import { DragObjectWithType, useDrop } from 'react-dnd-cjs';
import { Spin } from 'antd';
import { useFela } from '../theme';
import React from 'react';

export type IDropPanelItem = DragObjectWithType & {
  placement_id?: string;
  job_id: string;
};
export interface IDropPanel extends IPanel {
  onDrop?: (item: IDropPanelItem) => void;
}

export function DropPanel({ onDrop, ...props }: IDropPanel) {
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: 'job',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: onDrop ? (item: IDropPanelItem) => onDrop(item) : onDrop,
  });

  return <Panel ref={dropRef} canDrop={canDrop} isOver={isOver} {...props} />;
}

interface IPanel {
  children?: React.ReactNode | Array<React.ReactNode>;
  background?: React.ReactNode | Array<React.ReactNode>;
  className?: string;
  color?: string;
  loading?: boolean;

  ratio?: number;
  base?: number;

  canDrop?: boolean;
  isOver?: boolean;
}

function _Panel(
  {
    children,
    className,

    background,
    color,
    loading,

    ratio = 1,
    base,

    canDrop,
    isOver,
  }: IPanel,
  ref: React.Ref<HTMLDivElement>,
) {
  const { css, theme } = useFela();

  return (
    <div
      ref={ref}
      className={`${css({
        position: 'relative',
        backgroundColor:
          canDrop && isOver ? theme.palette.primary.base : color || 'none',
      })} ${className || ''}`}
    >
      <div className={css({ marginTop: `${ratio * 100}%` })} />

      {!loading && !!background && (
        <div
          className={css({
            center: 'true',
            opacity: !canDrop ? 0.7 : 1,
            transition: 'opacity 0.3s',
            size: 32,
            '> svg': {
              size: 32,
              fill:
                canDrop && isOver ? theme.white : theme.palette.primary.base,
            },
          })}
        >
          {background}
        </div>
      )}

      {loading ? (
        <div className={css({ center: 'true' })}>
          <Spin />
        </div>
      ) : (
        !!children && (
          <div
            className={css({
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              alignContent: 'start',
              alignItems: 'start',
              justifyContent: 'inherit',
              flexWrap: 'wrap',
              '> div': {
                flexBasis: `calc(100% / ${base})`,
                opacity: canDrop ? 0.35 : 1,
                transition: 'opacity 0.3s',
              },
            })}
          >
            {children}
          </div>
        )
      )}
    </div>
  );
}
export const Panel = React.forwardRef(_Panel);
