import { Tag as AntdTag } from 'antd';
import { ReactComponent as Check } from '../icons/check.svg';
import { ReactComponent as Times } from '../icons/times.svg';
import { useFela } from '../theme';
import React from 'react';

export function Tag({
  children,
  no,
  onClick,
}: {
  children: React.ReactNode | Array<React.ReactNode>;
  no?: boolean;
  onClick: () => void;
}) {
  const { css, theme } = useFela();

  return (
    <AntdTag
      onClick={onClick}
      color={no ? 'error' : 'success'}
      className={css({
        ellipsis: 180,
        marginY: 1,
        cursor: 'pointer',
        onHover: {
          color: theme.black,
          backgroundColor: theme.white,
          borderColor: theme.black,
          '& svg': {
            color: theme.black,
          },
        },
      })}
    >
      <span
        className={`${css({
          '> svg': {
            size: theme.font.sm.fontSize,
            fill: !no ? theme.palette.success.base : theme.palette.error.base,
            marginRight: theme.spacing.xs,
          },
        })} anticon anticon-close`}
      >
        {!no ? <Check /> : <Times />}
      </span>
      {children}
    </AntdTag>
  );
}
