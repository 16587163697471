import { Content } from 'pdfmake/interfaces';

const imageExists = async (image_url: string) => {
  const result = await fetch(image_url, { method: 'HEAD' });

  return result.ok;
};

const urlToBase64 = (url: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.height = img.height;
      canvas.width = img.width;
      ctx?.drawImage(img, 0, 0);

      resolve(canvas.toDataURL('image/jpeg'));
    };
    img.onerror = (error) => reject(error);
  });

// Rotieren für z.B. Hochformat
// const rotateBase64Image = (base64data: string): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const img = new Image();
//     img.src = base64data;
//     img.onload = () => {
//       const canvas = document.createElement("canvas"),
//         ctx = canvas.getContext("2d");

//       canvas.width = img.height;
//       canvas.height = img.width;
//       ctx?.rotate((-90 * Math.PI) / 180);
//       ctx?.translate(-canvas.height, 0);
//       ctx?.drawImage(img, 0, 0);

//       resolve(canvas.toDataURL());
//     };
//     img.onerror = (error) => reject(error);
//   });

export default async function getBoard(id: string): Promise<Array<Content>> {
  const url = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD}/image/upload/${id}`;
  let image = '';

  if (id)
    if (await imageExists(url)) image = await urlToBase64(url);
    else
      try {
        const response = await fetch(
          process.env.REACT_APP_AUSWERTEN_API || '',
          {
            method: 'POST',
            headers: { public_id: id },
            body: '',
          },
        );
        const { errors, screenshot } = await response.json();

        if (errors)
          throw Error(
            Array.isArray(errors)
              ? errors.map(({ msg }: { msg: string }) => msg).join(', ')
              : errors,
          );

        if (screenshot) image = 'data:image/jpeg;base64,' + screenshot;
      } catch (error) {
        console.error('Unvalid id or network issues.', error);
      }

  return image
    ? [
        // {
        //   text: "IbeA-Ergebnisse",
        //   fontSize: 24,
        //   bold: true,
        //   margin: [0, 0, 0, 32],
        // },
        {
          image, // Für Hochformat : await rotateBase64Image(image),
          width: 680,
          style: 'centered',
        },
      ]
    : [];
}
