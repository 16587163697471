import { Drawer as AntdDrawer, Spin } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { LoadingOutlined } from '@ant-design/icons';
import { SpinProps } from 'antd/lib/spin';
import { useFela } from '../theme';
import React from 'react';

interface IDrawer extends DrawerProps {
  children?: React.ReactElement<{ loading?: boolean }>[];
  loading?: boolean;
  footer?: React.ReactNode | Array<React.ReactNode>;
}
interface IDrawerContent extends SpinProps {
  loading?: boolean;
  children?: React.ReactNode;
}
interface IDrawerFooter {
  children?: React.ReactNode;
}

function _Drawer({ children, className, visible, loading, ...rest }: IDrawer) {
  const { css, theme } = useFela();

  return (
    <AntdDrawer
      placement="right"
      width={400}
      {...rest}
      visible={visible}
      className={`${css({
        '> .ant-drawer-content-wrapper': {
          maxWidth: '100%',
          '& .ant-drawer-header': {
            border: 0,
            boxShadow: theme.boxShadow[1].toString,
            height: theme.antd['layout-header-height'],
            paddingLeft: theme.spacing.xl,
            paddingRight: theme.antd['layout-header-height'],
            paddingY: 0,
            '> .ant-drawer-title': {
              color: theme.palette.primary.base,
              textTransform: 'uppercase',
              fontFamily: 'Comfortaa, cursive',
              lineHeight: `${theme.antd['layout-header-height']}px`,
              ellipsis: 'true',
            },
          },
          '& .ant-drawer-body': {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          },
        },
      })} ${className || ''}`}
    >
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { loading })
          : child,
      )}
    </AntdDrawer>
  );
}

function DrawerContent({ loading, spinning, ...rest }: IDrawerContent) {
  const { css, theme } = useFela();

  return (
    <Spin
      {...rest}
      spinning={loading || spinning || false}
      indicator={<LoadingOutlined translate="no" />}
      size="large"
      wrapperClassName={css({
        flexGrow: 1,
        paddingX: theme.spacing.xl,
        paddingY: theme.spacing.lg,
      })}
    />
  );
}

function DrawerFooter({ children }: IDrawerFooter) {
  const { css, theme } = useFela();

  return (
    <div
      className={css({
        boxShadow: theme.boxShadow[1].toString,
        width: '100%',
        paddingX: theme.spacing.md,
        paddingY: theme.spacing.sm,
        textAlign: 'right',
        '> button': {
          marginLeft: theme.spacing.sm,
        },
        ifMiniDown: {
          paddingBottom: theme.spacing.xxl,
        },
      })}
    >
      {children}
    </div>
  );
}

_Drawer.Content = DrawerContent;
_Drawer.Footer = DrawerFooter;
export const Drawer = _Drawer;
