import { DropPanel, IDropPanel, Panel } from './panel';
import { Title } from './title';
import { useFela } from '../theme';
import React from 'react';

interface IPanelContainer extends IDropPanel {
  children?: React.ReactNode | Array<React.ReactNode>;
  title?: string;
}

export function PanelContainer({
  children,
  onDrop,
  title,
  loading,
  ratio,
  background,
}: IPanelContainer) {
  const { css, theme } = useFela();

  const _Panel = onDrop ? DropPanel : Panel;

  const base = React.useMemo(
    () =>
      ratio
        ? Math.ceil(Math.sqrt(React.Children.count(children) * ratio)) / ratio
        : 1,
    [children, ratio],
  );

  return (
    <>
      {!!title && <Title>{title}</Title>}

      <_Panel
        loading={loading}
        onDrop={onDrop}
        ratio={ratio}
        base={base}
        background={background}
        className={css({
          border: `2px solid ${theme.palette.primary.base}`,
          borderRadius: theme.border.base.borderRadius,
          boxShadow: theme.boxShadow[1].toString,
          marginTop: theme.spacing.xxs,
        })}
      >
        {children}
      </_Panel>
    </>
  );
}
