import { Job } from './job';
import { XYCoord, useDragLayer } from 'react-dnd-cjs';
import { useFela } from '../theme';

function getItemStyles(currentOffset?: XYCoord | null) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

export function DragLayer() {
  const { css, theme } = useFela();
  const { item, itemType, currentOffset, isDragging } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );

  function renderItem() {
    switch (itemType) {
      case 'job':
        return (
          <Job
            className={css({
              boxShadow: theme.boxShadow[3].toString,
            })}
          >
            {item.children}
          </Job>
        );
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 1,
        opacity: 0.85,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div style={getItemStyles(currentOffset)}>{renderItem()}</div>
    </div>
  );
}
