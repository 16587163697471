import { QueryHookOptions, QueryResult } from '@apollo/client';
import {
  QuestionQuery,
  QuestionQueryVariables,
  useQuestionQuery,
} from '../autogenerated';
import gql from 'graphql-tag';

gql`
  query question($id: uuid) {
    question(where: { id: { _eq: $id } }) {
      active
      id
      text
      no
      order
      yes
      name
    }
  }
`;

export type QuestionType = {
  active?: boolean | null;
  id: string;
  text?: string | null;
  no?: string | null;
  order?: number | null;
  yes?: string | null;
  name: string;
};

type UseQuestionReturnDataType = Array<QuestionType>;

export function useQuestion(
  props?: QueryHookOptions<QuestionQuery, QuestionQueryVariables>,
): [
  UseQuestionReturnDataType,
  QueryResult<QuestionQuery, QuestionQueryVariables>,
] {
  const result = useQuestionQuery(props);

  return [result.data?.question || [], result];
}
