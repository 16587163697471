import { useInsertPlacementsMutation } from '../autogenerated';
import gql from 'graphql-tag';

gql`
  mutation insert_placements($objects: [placement_insert_input!]!) {
    insert_placement(
      on_conflict: {
        constraint: classification_pkey
        update_columns: [x, y, dropped_at]
      }
      objects: $objects
    ) {
      returning {
        id
      }
    }
  }
`;

export function usePlacementsInsert() {
  return useInsertPlacementsMutation();
}
