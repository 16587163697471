import { HomeOutlined } from '@ant-design/icons';
import { InputNumber, Menu, Tooltip } from 'antd';
import { Page } from '..';
import { useFela } from '../../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSession } from '../../graphql';
import React from 'react';

interface IAdmin {
  children?: React.ReactNode | Array<React.ReactNode>;
  addMultiple?: boolean;
  actions?: JSX.Element;
  onAdd?: (_?: number) => void;
}

export function AdminLayout({ children, addMultiple, actions, onAdd }: IAdmin) {
  const { css, theme } = useFela();
  const { pathname } = useLocation();
  const push = useNavigate();
  const { session } = useSession();
  const selectedKey = React.useMemo(() => {
    const key = pathname.split('/').pop();

    return key !== 'admin' ? key : 'users';
  }, []);

  const [count, setCount] = React.useState<number>(1);

  return (
    <Page title="Administration" full>
      <div
        className={css({
          backgroundColor: theme.palette.primary[1],
          borderBottom: `1px solid ${theme.palette.primary[2]}`,
          display: 'flex',
        })}
      >
        <Menu
          mode="horizontal"
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          className={css({
            backgroundColor: 'transparent',
            border: 'none',
            flexGrow: 1,
          })}
        >
          <Menu.Item key="home" onClick={() => push('/')}>
            <HomeOutlined translate="no" />
          </Menu.Item>
          <Menu.Item key="users" onClick={() => push('/admin/users')}>
            Benutzer
          </Menu.Item>
          {session.isAdmin && (
            <>
              <Menu.Item
                key="datenmatrix"
                onClick={() => push('/admin/datenmatrix')}
              >
                Datenmatrix
              </Menu.Item>
              <Menu.Item key="jobs" onClick={() => push('/admin/jobs')}>
                Berufe
              </Menu.Item>
              <Menu.Item
                key="questions"
                onClick={() => push('/admin/questions')}
                disabled
              >
                Fragen
              </Menu.Item>
            </>
          )}
        </Menu>

        <Menu
          mode="horizontal"
          className={css({
            backgroundColor: 'transparent',
            border: 'none',
            flexGrow: 1,
            justifyContent: 'flex-end',
          })}
        >
          {!!onAdd &&
            session.isAdmin &&
            (addMultiple ? (
              <Tooltip
                title={
                  <InputNumber
                    placeholder="Anzahl"
                    value={count}
                    onChange={(count) => setCount(parseInt(`${count || 0}`))}
                  />
                }
              >
                <Menu.Item key="add" onClick={() => onAdd(count)}>
                  Hinzufügen ({count})
                </Menu.Item>
              </Tooltip>
            ) : (
              <Menu.Item key="add" onClick={() => onAdd(count)}>
                Hinzufügen
              </Menu.Item>
            ))}

          {actions}
        </Menu>
      </div>

      <div className={css({ overflowY: 'auto' })}>{children}</div>
    </Page>
  );
}
