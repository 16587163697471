import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from './use-query';
import { useQuestions, useRepetition } from '../graphql';
import React from 'react';

type TStep = {
  id: string | null;
  slug: string;
  remaining?: number;
};

export function useNav(): [
  (() => void) | undefined,
  (() => void) | undefined,
  TStep,
] {
  const push = useNavigate();
  const { pathname } = useLocation();
  const [questions] = useQuestions();
  const [repetition] = useRepetition();
  const query = useQuery();

  const steps: Array<TStep> = [
    {
      id: null,
      slug: '/',
    },
  ];

  if (!repetition?.completed_at) {
    if (repetition?.show_questions)
      steps.push({
        id: null,
        slug: '/antworten',
      });
    steps.push({
      id: null,
      slug: '/platzieren',
    });
    questions.forEach(({ id }) =>
      steps.push({
        id,
        slug: `/eingrenzen?id=${id}`,
      }),
    );
  }

  steps.push({
    id: null,
    slug: '/auswerten',
  });

  const currentIndex = React.useMemo(
    () =>
      steps.findIndex(
        ({ slug, id }) => slug.startsWith(pathname) && id === query.get('id'),
      ),
    [pathname, query.get('id'), steps],
  );
  const onPrev = React.useMemo(
    () =>
      currentIndex && push
        ? () => push(steps[currentIndex - 1]?.slug)
        : undefined,
    [steps, currentIndex],
  );
  const onNext = React.useMemo(
    () =>
      currentIndex < steps.length - 1 && push
        ? () => push(steps[currentIndex + 1]?.slug)
        : undefined,
    [steps, currentIndex],
  );

  return [onPrev, onNext, steps[currentIndex + 1]];
}
