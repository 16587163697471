import {
  DeleteJobMutation,
  DeleteJobMutationVariables,
  InsertJobsMutation,
  InsertJobsMutationVariables,
  useDeleteJobMutation,
  useInsertJobsMutation,
} from '../../autogenerated';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import gql from 'graphql-tag';

gql`
  mutation insert_jobs($objects: [job_insert_input!]!) {
    insert_job(
      on_conflict: {
        constraint: jobs_pkey
        update_columns: [active, description, name, r, i, a, s, e, c]
      }
      objects: $objects
    ) {
      returning {
        id
      }
    }
  }
`;

gql`
  mutation delete_job($id: uuid!) {
    delete_user_jobs(where: { job_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_placement(where: { job_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_job(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export function useAdminJob(): [
  (
    options?: MutationFunctionOptions<
      InsertJobsMutation,
      InsertJobsMutationVariables
    >,
  ) => Promise<FetchResult<InsertJobsMutation>>,
  (
    options?: MutationFunctionOptions<
      DeleteJobMutation,
      DeleteJobMutationVariables
    >,
  ) => Promise<FetchResult<DeleteJobMutation>>,
  boolean,
] {
  const [insertJobs, { loading: iLoading }] = useInsertJobsMutation();
  const [deleteJob, { loading: dLoading }] = useDeleteJobMutation();

  return [insertJobs, deleteJob, iLoading || dLoading];
}
