import { Button } from 'antd';
import { useFela } from '../theme';
import Player from './player';
import React from 'react';

export interface IModal {
  children?: React.ReactNode | Array<React.ReactNode>;
  image: string;
  audio?: string;
  title: string | React.ReactNode;
  open?: boolean;
  onClose: () => void;
}

export function Modal({
  children,
  image,
  audio,
  title,
  open,
  onClose,
}: IModal) {
  const { css, theme } = useFela();

  if (process.env.NODE_ENV === 'development') return <div />;

  return (
    <div
      onClick={onClose}
      className={css({
        display: open ? 'block' : 'none',
        bottom: 0,
        left: 0,
        margin: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1020,
      })}
    >
      <div
        className={css({
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
        })}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={css({
            color: theme.white,
            textShadow: theme.textShadow.base.toString,
            fontFamily: theme.font.base.fontFamily,
            fontSize: theme.heading[2].fontSize,
          })}
        >
          {title}
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className={css({
            borderRadius: theme.border.base.borderRadius,
            position: 'relative',
            backgroundColor: theme.white,
            boxShadow: theme.boxShadow[1].toString,
            padding: theme.spacing.lg,
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.spacing.lg,
              fontSize: '120% !important',
              fontStyle: 'italic',
              fontWeight: 'bold',
            })}
          >
            <div className={css({ marginRight: theme.spacing.md })}>
              {children}
            </div>

            <div
              className={css({
                width: '40%',
                flexGrow: 0,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.md,
              })}
            >
              <img
                src={image}
                className={css({
                  width: '100%',
                  border: `1px solid ${theme.palette.primary.base}`,
                })}
              />

              {!!audio && !!open && <Player url={audio} />}
            </div>
          </div>

          <Button
            type="primary"
            size="large"
            onClick={onClose}
            className={css({
              width: '100%',
              boxShadow: theme.boxShadow[3].toString,
            })}
          >
            Verstanden und Weiter
          </Button>
        </div>
      </div>
    </div>
  );
}
