import { useExcludingQuestionMutation } from '../autogenerated';
import gql from 'graphql-tag';

gql`
  mutation excluding_question($id: uuid!, $question_id: uuid) {
    update_placement(
      where: {
        _and: {
          id: { _eq: $id }
          repetition: { completed_at: { _is_null: true } }
        }
      }
      _set: { excluding_question_id: $question_id }
    ) {
      returning {
        id
      }
    }
  }
`;

export function useExcludingQuestion() {
  return useExcludingQuestionMutation();
}
