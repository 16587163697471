import { Slider as AntdSlider, SliderSingleProps } from 'antd';
import { FunctionComponent, forwardRef } from 'react';
import { useFela } from '../theme';

type SliderType = SliderSingleProps & {
  leftIcon: FunctionComponent<{ className?: string }>;
  rightIcon: FunctionComponent<{ className?: string }>;
};

function _Slider(
  {
    value,
    max = 100,
    min = 0,
    leftIcon: LIcon,
    rightIcon: RIcon,
    ...rest
  }: SliderType,
  ref: React.Ref<HTMLDivElement>,
) {
  const { css, theme } = useFela();

  const mid = (max - min) / 2 + min;
  const iconContainerStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: 24,
    width: 24,
  };
  const iconStyle = {
    height: '100%',
    width: '100%',
    maxWidth: 20,
    marginX: 'auto',
  };

  return (
    <div
      className={css({
        position: 'relative',
        paddingX: theme.spacing.xl,
      })}
    >
      <div className={css({ ...iconContainerStyle, left: 0 })}>
        <LIcon
          className={css({
            ...iconStyle,
            fill:
              (value || 0) < mid
                ? theme.palette.primary.base
                : theme.palette.grey.base,
          })}
        />
      </div>

      <AntdSlider
        value={value}
        max={max}
        min={min}
        ref={ref}
        tooltipVisible={false}
        {...rest}
      />

      <div className={css({ ...iconContainerStyle, right: 0 })}>
        <RIcon
          className={css({
            ...iconStyle,
            fill:
              (value || 0) > mid
                ? theme.palette.primary.base
                : theme.palette.grey.base,
            right: 0,
          })}
        />
      </div>
    </div>
  );
}
export const Slider = forwardRef(_Slider);
