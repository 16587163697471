import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React from 'react';

interface ICheckbox extends Omit<CheckboxProps, 'onChange'> {
  value?: boolean;
  onChange?: (bool: boolean) => void;
}

export function Checkbox({
  value,
  onChange,
  indeterminate,
  ...props
}: ICheckbox) {
  const [_indeterminate, setIndeterminate] = React.useState(indeterminate);
  React.useEffect(() => setIndeterminate(indeterminate), [indeterminate]);

  const _onChange = React.useCallback(
    (e: { target: { checked: boolean } }) => {
      if (onChange) onChange(e.target.checked);
      setIndeterminate(undefined);
    },
    [onChange],
  );

  return (
    <AntdCheckbox
      checked={!!value}
      indeterminate={_indeterminate}
      onChange={_onChange}
      {...props}
    />
  );
}
