import './nprogress.css';
import 'antd/dist/antd.less';
import { BrowserRouter } from 'react-router-dom';
import { FelaProvider } from './theme';
import { Router } from './components';
import { SessionProvider } from './graphql';
import ConfigProvider from 'antd/lib/config-provider';
import ReactDOM from 'react-dom';
import de_DE from 'antd/lib/locale-provider/de_DE';
import moment from 'moment';

moment.locale('de-DE');

ReactDOM.render(
  <FelaProvider>
    <ConfigProvider locale={de_DE}>
      <BrowserRouter>
        <SessionProvider uri={process.env.REACT_APP_HASURA_HOSTNAME}>
          <Router />
        </SessionProvider>
      </BrowserRouter>
    </ConfigProvider>
  </FelaProvider>,
  document.getElementById('root'),
);
