import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  TreeSelect as _TreeSelect,
  message,
  notification,
} from 'antd';
import { Checkbox, Drawer } from '..';
import { LabeledValue, TreeSelectProps } from 'antd/lib/tree-select';
import { Store } from 'rc-field-form/lib/interface';
import { UserJobsConstraint } from '../../graphql/autogenerated';
import {
  UserJobsType,
  useAdminUsers,
  useJobs,
  useSession,
  useSetQuestionnareRepetition,
} from '../../graphql';
import { isEqual } from 'lodash';
import { useFela } from '../../theme';
import React from 'react';
import moment, { Moment } from 'moment';

const riasec = ['r', 'i', 'a', 's', 'e', 'c'] as const;

function TreeSelect<T extends LabeledValue>({
  value,
  ...rest
}: TreeSelectProps<T>) {
  const { css, theme } = useFela();

  return (
    <>
      <_TreeSelect value={value} {...rest} />
      {!value && (
        <span className={css({ color: theme.palette.grey.base })}>
          Jobs bleiben unverändert
        </span>
      )}
    </>
  );
}

export type UserFormType = {
  id?: string;
  name?: string;
  group?: string;
  is_admin?: boolean;
  random_mode?: boolean;
  user_jobs?: Array<UserJobsType>; //  => Array<string>
  active?: boolean;
  valid_until?: string;
};
type AdminUserFormType = {
  users: Array<UserFormType>;
  onClose: () => void;
};

export function AdminUserForm({ users, onClose }: AdminUserFormType) {
  const { session } = useSession();

  const [form] = Form.useForm();

  const [user_jobs] = useJobs();
  const treeData = React.useMemo(
    () =>
      // select for each r|i|a|s|e|c one job with value 3
      riasec.map((type) => ({
        title: type,
        value: type,
        key: type,
        children: user_jobs
          .filter((job) => job[type] === 3)
          .map((job) => ({
            title: job.name?.replace(/#/g, '').replace(/\|/g, '\n'),
            value: job.id,
            key: job.id,
          })),
      })),
    [user_jobs],
  );

  const { insertUsers, deleteUsersJobs, loading } = useAdminUsers();

  const [showQuestionnare] = useSetQuestionnareRepetition();

  const fields = React.useMemo(() => {
    if (!users.length) return [];

    const defaultFields = [
      'name', // string
      'group', // string
      'is_admin', // boolean
      'random_mode', // boolean
      'user_jobs', // Array<UserJobsType> => Array<string>
      'active', // boolean
      'valid_until', // string
    ] as const;
    const fields = defaultFields.map((key) => {
      let value:
        | string
        | boolean
        | Moment
        | Array<string>
        | Array<UserJobsType>
        | typeof riasec
        | undefined;

      // Wert nur übernehmen, wenn bei allen ausgewählten Benutzern gleich
      value = users.every((user) => isEqual(user?.[key], users[0]?.[key]))
        ? users[0]?.[key]
        : undefined;

      // Default values
      if (key === 'valid_until' && typeof value === 'string')
        value = moment(value);
      if (key === 'user_jobs' && Array.isArray(value))
        value = value?.map((v) => v.job_id);

      return {
        name: [key],
        value,
        touched: false,
      };
    });

    return fields;
  }, [users]);

  const onFinish = React.useCallback(
    async ({ user_jobs, ...values }: Store) => {
      // if jobs = [riasec] (all jobs selected) => empty Array!
      if (
        riasec.length === user_jobs?.length &&
        riasec.every((value) => user_jobs?.includes(value))
      )
        user_jobs = [];
      // riasec-categories => jobs
      else
        riasec.forEach((type) => {
          if (user_jobs?.includes(type)) {
            const { children } =
              treeData.find((data) => data.value === type) || {};
            const jobs = children?.map((data) => data.value) || [];

            user_jobs = [
              ...user_jobs?.filter((jobs: string) => jobs !== type),
              ...jobs,
            ];
          }
        });

      const objects = users.map((user) => ({
        id: user?.id,
        name: users.length > 1 ? user?.name : values.name || user?.name,
        group: values.group,
        active: values.active ?? user?.active,
        valid_until:
          values.valid_until === undefined
            ? user?.valid_until
              ? moment(user?.valid_until).toISOString()
              : undefined
            : values.valid_until === null
            ? null
            : values.valid_until.toISOString(),
        is_admin: values.is_admin ?? user?.is_admin,
        random_mode: values.random_mode ?? user?.random_mode,
        user_jobs: user_jobs
          ? {
              data: user_jobs.map((job_id: string) => ({ job_id })),
              on_conflict: {
                constraint: UserJobsConstraint.UserJobsPkey,
                update_columns: [],
              },
            }
          : undefined,
      }));

      try {
        // Alte Jobs löschen (wenn existenter User und Jobs überschrieben werden sollen)
        const user_ids = objects
          ?.filter((user) => !!user.id && !!user.user_jobs)
          .map((user) => user.id);
        await deleteUsersJobs({ variables: { user_ids } });

        // Benutzer einfügen bzw updaten (wenn id gesetzt und User vorhanden)
        const usersResponse = await insertUsers({
          variables: { objects },
          refetchQueries: ['users'],
        });

        const onSuccess = () =>
          notification.success({
            message: 'Benutzer erfolgreich gespeichert!',
            description: (
              <div>
                Folgende Benutzer wurden erfolgreich gespeichert:
                <ul>
                  {usersResponse.data?.insert_user?.returning.map((user) => (
                    <li key={user.id}>{user.name}</li>
                  ))}
                </ul>
                <Button
                  type="primary"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        usersResponse.data?.insert_user?.returning
                          .map((user) => user.name)
                          .join(', ') || '',
                      )
                      .then(() =>
                        message.info('Benutzer in Zwischenablage kopiert!'),
                      )
                  }
                >
                  In Zwischenablage kopieren
                </Button>
              </div>
            ),
            duration: 0,
          });

        if (!users.filter((user) => !!user.id).length)
          Modal.confirm({
            title: 'Fragebogen anzeigen?',
            content: 'Soll der Fragebogen angezeigt werden?',
            okText: 'Anzeigen',
            cancelText: 'Nicht anzeigen',
            onOk: () =>
              usersResponse.data?.insert_user?.returning.forEach((user) =>
                showQuestionnare({ variables: { user_id: user.id } }),
              ),
            onCancel: () => undefined,
            afterClose: onSuccess,
          });
        else onSuccess();
      } catch (e) {
        message.error('Fehler beim Speichern!');

        throw e;
      } finally {
        onClose();
      }
    },
    [users],
  );

  const onCloseConfirm = React.useCallback(() => {
    if (form.isFieldsTouched())
      Modal.confirm({
        title: 'Änderungen verwerfen?',
        content: 'Möchten Sie Ihre Änderungen ohne zu Speichern verwerfen?',
        okText: 'Verwerfen',
        cancelText: 'Abbrechen',
        onOk: onClose,
        onCancel: () => undefined,
      });
    else onClose();
  }, []);

  return (
    <Drawer
      title={`${users.length} Benutzer ${
        !users[0]?.id ? 'anlegen' : 'bearbeiten'
      }`}
      visible={!!users.length}
      loading={loading}
      onClose={onClose}
    >
      <Drawer.Content>
        <Form form={form} layout="vertical" fields={fields} onFinish={onFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={
              users.length === 1
                ? [{ required: true, message: 'Feld darf nicht leer sein!' }]
                : undefined
            }
          >
            <Input placeholder="Name" disabled={users.length > 1} allowClear />
          </Form.Item>
          <Form.Item label="Jobreihenfolge" name="random_mode">
            <Checkbox
              indeterminate={
                users.some((u) => !!u?.random_mode) &&
                !users.every((u) => !!u?.random_mode)
              }
            >
              Jobs in beliebiger Reihenfolge anzeigen?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Jobs" name="user_jobs">
            <TreeSelect
              treeData={treeData}
              treeNodeFilterProp="title"
              treeCheckable
              showArrow
              showCheckedStrategy={_TreeSelect.SHOW_PARENT}
            />
          </Form.Item>
          <Form.Item label="Administrator" name="is_admin">
            <Checkbox
              disabled={!!users.find((user) => user.id === session.id)}
              indeterminate={
                users.some((u) => !!u?.is_admin) &&
                !users.every((u) => !!u?.is_admin)
              }
            >
              Benutzer hat Administrationsrechte?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Aktiv" name="active">
            <Checkbox
              disabled={!!users.find((user) => user.id === session.id)}
              indeterminate={
                users.some((u) => !!u?.active) &&
                !users.every((u) => !!u?.active)
              }
            >
              Benutzer ist aktiv?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Ablaufdatum" name="valid_until">
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label="Gruppe" name="group">
            <Input placeholder="Gruppe" allowClear />
          </Form.Item>
        </Form>
      </Drawer.Content>
      <Drawer.Footer>
        <Button onClick={onCloseConfirm}>Abbrechen</Button>{' '}
        <Button type="primary" loading={loading} onClick={form.submit}>
          Speichern
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
}
