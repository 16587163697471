import { ReactComponent as Down } from '../icons/chevron-down.svg';
import { DragJob, IJob, Job } from './job';
import { DropPanel, IDropPanelItem, Panel } from './panel';
import { ReactComponent as Female } from '../icons/female.svg';
import { ReactComponent as Left } from '../icons/chevron-left.svg';
import { ReactComponent as Male } from '../icons/male.svg';
import { ReactComponent as Plus } from '../icons/plus.svg';
import { ReactComponent as Right } from '../icons/chevron-right.svg';
import { Spin } from 'antd';
import { ReactComponent as ThumbsDown } from '../icons/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../icons/thumbs-up.svg';
import { ReactComponent as Up } from '../icons/chevron-up.svg';
import { useFela } from '../theme';
import React from 'react';
import theme from '../theme.json';

interface IField {
  id: string;
  x: number;
  y: number;
  job: { id: string; name: string; description?: string | null };
}

export interface IBoard {
  className?: string;
  fields?: Array<IField>;
  onDrop?: (item: IDropPanelItem & { x: number; y: number }) => void;
  loading?: boolean;

  activeArea?: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  activeColor?: keyof typeof theme.palette;

  jobProps?: (placement: IField) => IJob;
}

export function Board({
  className,
  fields,
  onDrop,
  loading,

  activeArea,
  activeColor = 'primary',

  jobProps,
}: IBoard) {
  const { css, theme } = useFela();
  const _Panel = onDrop ? DropPanel : Panel;
  const _Job = onDrop ? DragJob : Job;

  const coords = React.useMemo(() => {
    const arr: Array<Array<Array<IField>>> = [];

    // init Array
    for (let y = 0; y < 9; y++) {
      arr[y] = [];

      for (let x = 0; x < 9; x++) arr[y][x] = [];
    }

    // set fields
    fields?.forEach((item) => {
      const { x, y } = item;

      arr[y][x].push(item);
    });

    return arr;
  }, [fields]);

  return (
    <div
      className={css({
        position: 'relative',
        flexGrow: 1,
        padding: theme.spacing.sm,
        overflow: 'visible',
      })}
    >
      {!!loading && (
        <div
          className={css({
            center: 'true',
            zIndex: 1,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.67)',
            fontWeight: 'bold',
          })}
        >
          <Spin
            tip="Lade Inhalte"
            size="large"
            className={css({ center: 'true' })}
          />
        </div>
      )}

      <div
        className={`${css({
          display: 'flex',
          flexWrap: 'wrap',
          borderRadius: theme.border.base.borderRadius,
          overflow: 'hidden',
          '> div': { flexBasis: `calc(100% / 9)` },
          ifLargeDown: {
            flexWidth: `calc(100vh - ${2 * theme.spacing.sm}px)`,
          },
        })} ${className || ''}`}
      >
        {coords.map((arr, y) =>
          arr.map((placements, x) => {
            const background = onDrop ? (
              x === 4 && y === 4 ? (
                <Plus />
              ) : x === 0 && y === 4 ? (
                <Left />
              ) : x === 8 && y === 4 ? (
                <Right />
              ) : x === 4 && y === 0 ? (
                <Up />
              ) : x === 4 && y === 8 ? (
                <Down />
              ) : x === 1 && y === 4 ? (
                <Male />
              ) : x === 7 && y === 4 ? (
                <Female />
              ) : x === 4 && y === 1 ? (
                <ThumbsUp />
              ) : x === 4 && y === 7 ? (
                <ThumbsDown />
              ) : null
            ) : null;
            const _activeColor =
              activeArea &&
              activeArea?.top <= y &&
              activeArea?.bottom >= y &&
              activeArea?.left <= x &&
              activeArea?.right >= x &&
              theme.palette[activeColor][2];
            const { length } = placements;

            return (
              <_Panel
                key={`${x}_${y}`}
                onDrop={onDrop ? (item) => onDrop({ ...item, x, y }) : onDrop}
                color={
                  _activeColor
                    ? _activeColor
                    : !((y * 9 + x) % 2)
                    ? theme.palette.grey[1]
                    : undefined
                }
                background={background}
                base={length > 1 ? Math.ceil(Math.sqrt(length)) : 1}
              >
                {length &&
                  placements.map((placement) => {
                    const props = jobProps ? jobProps(placement) : {};

                    return (
                      <_Job
                        key={placement.id}
                        placement_id={placement.id}
                        job_id={placement.job?.id}
                        hue={onDrop ? x : undefined}
                        saturation={onDrop ? 8 - y : undefined}
                        {...props}
                        showContent={
                          placements.length <= 1 &&
                          (props.showContent !== undefined
                            ? props.showContent
                            : true)
                        }
                      >
                        {placement.job?.name}
                      </_Job>
                    );
                  })}
              </_Panel>
            );
          }),
        )}
      </div>
    </div>
  );
}
