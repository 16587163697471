import { Content } from 'pdfmake/interfaces';

type PlacementsType = Array<{
  x: number;
  y: number;
  job: {
    name: string;
  };
  excluding_question?: {
    id: string;
  } | null;
}>;
type QuestionsType = Array<{
  id: string;
  name: string;
}>;

export default function getPlacements(
  placements: PlacementsType,
  questions?: QuestionsType,
): Array<Content> {
  return [
    {
      table: {
        headerRows: 1,
        widths: ['auto', ...(questions || [])?.map(() => '*'), '*', '*'],
        body: [
          [
            { text: `Beruf (${placements?.length})`, style: 'tableHeader' },
            ...(questions || [])?.map((question) => ({
              text: question.name,
              style: 'tableHeader',
              alignment: 'center',
            })),
            {
              text: 'Prestige\n1 (niedrig) - 9 (hoch)',
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: 'Gender\n1 (m) - 9 (w)',
              style: 'tableHeader',
              alignment: 'center',
            },
          ],
          ...(placements || []).map((placement) => {
            let hasNo = false;

            return [
              {
                text: placement.job.name.replace(/#/g, '').replace(/\|/g, '\n'),
                noWrap: true,
              },
              ...(questions || [])?.map((question) => {
                if (hasNo) return { text: '-', alignment: 'center' };
                else if (placement.excluding_question?.id === question.id) {
                  hasNo = true;

                  return { text: 'Nein', alignment: 'center' };
                } else return { text: 'Ja', alignment: 'center' };
              }),
              { text: 9 - placement.y, alignment: 'center' },
              { text: placement.x + 1, alignment: 'center' },
            ];
          }),
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];
}
