import { QueryResult } from '@apollo/client';
import {
  RepetitionsQuery,
  RepetitionsQueryVariables,
  useCompleteRepetitionMutation,
  useRepetitionsQuery,
  useSaveQuestionnareRepetitionMutation,
  useSetQuestionnareRepetitionMutation,
} from '../autogenerated';
import gql from 'graphql-tag';

gql`
  query repetitions {
    repetition(where: { locked_at: { _is_null: true } }) {
      id
      completed_at
      show_questions
      questionnare
    }
  }
`;

type RepetitionType = {
  id?: string;
  completed_at?: string;
  show_questions?: boolean;
  questionnare?: JSON;
};

export function useRepetition(): [
  RepetitionType | undefined,
  QueryResult<RepetitionsQuery, RepetitionsQueryVariables>,
] {
  const result = useRepetitionsQuery();

  return [result.data?.repetition[0], result];
}

gql`
  mutation complete_repetition($user_id: uuid!) {
    update_repetition(
      where: {
        _and: { locked_at: { _is_null: true }, user_id: { _eq: $user_id } }
      }
      _set: { completed_at: "now()" }
    ) {
      returning {
        id
      }
    }
  }
`;

export function useCompleteRepetition() {
  return useCompleteRepetitionMutation();
}

gql`
  mutation set_questionnare_repetition($user_id: uuid!) {
    update_repetition(
      where: {
        _and: { locked_at: { _is_null: true }, user_id: { _eq: $user_id } }
      }
      _set: { show_questions: true }
    ) {
      returning {
        id
      }
    }
  }
`;

export function useSetQuestionnareRepetition() {
  return useSetQuestionnareRepetitionMutation();
}

gql`
  mutation save_questionnare_repetition($questionnare: jsonb!) {
    update_repetition(
      where: { locked_at: { _is_null: true } }
      _set: { questionnare: $questionnare }
    ) {
      returning {
        id
      }
    }
  }
`;

export function useSaveQuestionnareRepetition() {
  return useSaveQuestionnareRepetitionMutation();
}
