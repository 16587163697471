import { Button, Form, Input } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { useFela } from '../theme';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../graphql';
import React from 'react';

export function Login() {
  const { css, theme } = useFela();
  const [form] = Form.useForm();
  const { login } = useSession();
  const push = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onLogin = React.useCallback(async ({ code: name }: Store) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/${process.env.REACT_APP_LAMBDA_PATH}/${process.env.REACT_APP_LAMBDA_AUTH_ENDPOINT}`,
        {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ name }),
        },
      );
      const { errors, ...user } = await response.json();

      if (errors)
        throw Error(
          Array.isArray(errors)
            ? errors.map(({ msg }: { msg: string }) => msg).join(', ')
            : errors,
        );

      if (user) {
        await login(user.token);

        setLoading(false);
        if (user.roles.includes('admin')) push('/'); // force page.waitForNavigation() for screenshot API
      }
    } catch (error) {
      console.error(
        'You have an error in your code or there are Network issues.',
        error,
      );
      setLoading(false);
    }
  }, []);

  return (
    <Form
      form={form}
      name="login"
      onFinish={onLogin}
      className={css({
        display: 'flex',
        marginX: 'auto',
        marginTop: theme.spacing.md,
        border: `2px solid ${theme.palette.primary.base}`,
        borderRadius: theme.border.base.borderRadius,
        boxShadow: theme.boxShadow[1].toString,
        '> .ant-form-item': {
          margin: 0,
          width: '100%',
          '& .ant-form-item-control-input': {
            height: '100%',
          },
          '& button': {
            width: '100%',
          },
          '& input': {
            border: 'none',
          },
        },
      })}
    >
      <Form.Item
        name="code"
        hasFeedback={false}
        rules={[{ required: true, message: 'Feld darf nicht leer sein!' }]}
      >
        <Input placeholder="Zugangscode" size="large" disabled={loading} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          size="large"
          id="login_submit"
          loading={loading}
          onClick={form.submit}
          className={css({ borderRadius: 0 })}
        >
          Anmelden
        </Button>
      </Form.Item>
    </Form>
  );
}
