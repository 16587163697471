import { JobsQuery, JobsQueryVariables, useJobsQuery } from '../autogenerated';
import { QueryResult } from '@apollo/client';
import gql from 'graphql-tag';

gql`
  query jobs {
    job {
      active
      description
      id
      name
      r
      i
      a
      s
      e
      c
    }
  }
`;

type UseJobsReturnDataType = Array<{
  active?: boolean | null;
  description?: string | null;
  id?: string;
  name?: string;
  r?: number;
  i?: number;
  a?: number;
  s?: number;
  e?: number;
  c?: number;
}>;

export function useJobs(): [
  UseJobsReturnDataType,
  QueryResult<JobsQuery, JobsQueryVariables>,
] {
  const result = useJobsQuery();

  return [result.data?.job || [], result];
}
