import { AuswertungType, useAuswertung } from '../graphql';
import { Board, Info, ProgressBar, Sidebar } from '../components';
import { Email } from './email';
import { useFela } from '../theme';
import { useLocation } from 'react-router';
import React from 'react';

type PlacementsType =
  | {
      id: string;
      x: number;
      y: number;
      job: {
        id: string;
        name: string;
        description?: string | null | undefined;
        r: number;
        i: number;
        a: number;
        s: number;
        e: number;
        c: number;
      };
      excluding_question?:
        | {
            id: string;
          }
        | null
        | undefined;
    }[]
  | undefined;

const riasecArr = ['r', 'i', 'a', 's', 'e', 'c'] as const;
const riasecTitle = {
  r: 'Handwerklich-technisches Interesse',
  i: 'Wissenschaftlich-forschendes Interesse',
  a: 'Künstlerisches Interesse',
  s: 'Soziales Interesse',
  e: 'Unternehmerisches Interesse',
  c: 'Ordnend-verwaltendes Interesse',
};

export const getPlacements = (repetition: AuswertungType) => {
  if (repetition?.user?.user_jobs?.length)
    return repetition?.placements?.filter((placement) =>
      repetition?.user?.user_jobs?.find(
        (user_job) => placement.job.id === user_job.job_id,
      ),
    );
  return repetition?.placements || [];
};
export const getIncludedPlacements = (placements: PlacementsType) => {
  const includedPlacements: typeof placements = [];
  const includedRiasecIds: { [k: string]: Array<string> } = {
    r: [],
    i: [],
    a: [],
    s: [],
    e: [],
    c: [],
  };
  const excludedRiasecIds: { [k: string]: Array<string> } = {
    r: [],
    i: [],
    a: [],
    s: [],
    e: [],
    c: [],
  };

  placements?.forEach((placement) => {
    if (!placement.excluding_question?.id) includedPlacements.push(placement);

    riasecArr.forEach((key) => {
      if (placement.job[key] === 3)
        if (!placement.excluding_question?.id)
          includedRiasecIds[key].push(placement.id);
        else excludedRiasecIds[key].push(placement.id);
    });
  });

  return { includedPlacements, includedRiasecIds, excludedRiasecIds };
};
export const getActiveArea = (
  includedPlacements: PlacementsType,
  activePlacements?: PlacementsType,
) =>
  (activePlacements?.length ? activePlacements : includedPlacements)?.reduce(
    (acc, p) => ({
      top: p && acc.top > p.y ? p.y : acc.top,
      right: p && acc.right < p.x ? p.x : acc.right,
      bottom: p && acc.bottom < p.y ? p.y : acc.bottom,
      left: p && acc.left > p.x ? p.x : acc.left,
    }),
    { top: 9, right: -1, bottom: -1, left: 9 },
  );

export function Auswertung({ id }: { id?: string | null }) {
  const { pathname } = useLocation();
  const { css, theme } = useFela();

  // get infos from current user
  const [repetitions, { loading }] = useAuswertung(id || '');
  const { user, completed_at, locked_at } = repetitions[0] || {};

  // filter placements which are not supported (not in user_jobs)
  const placements = React.useMemo(
    () => getPlacements(repetitions[0]),
    [repetitions[0]],
  );
  const { includedPlacements, includedRiasecIds, excludedRiasecIds } =
    React.useMemo(() => getIncludedPlacements(placements), [placements]);

  const [activeRiasec, setActiveRiasec] =
    React.useState<(typeof riasecArr)[number]>();

  const activePlacements = React.useMemo(() => {
    const data: PlacementsType = [];

    (activeRiasec ? includedRiasecIds[activeRiasec] : []).forEach((pid) => {
      const item = includedPlacements?.find(({ id }) => id === pid);

      if (item) data.push(item);
    });

    return data;
  }, [activeRiasec, includedRiasecIds]);

  const activeArea = React.useMemo(
    () => getActiveArea(includedPlacements, activePlacements),
    [activePlacements, includedPlacements],
  );

  const fields = React.useMemo(
    () =>
      placements?.map(({ job, ...rest }) => ({
        job: {
          ...job,
          name: job.name.replace(/#/g, '').replace(/\|/g, '\n'),
        },
        ...rest,
      })),
    [placements],
  );

  return (
    <div
      className={css({
        display: 'flex',
        position: 'relative',
      })}
      id="content"
    >
      {!loading && !completed_at && !locked_at && (
        <>
          <div
            className={css({
              position: 'fixed',
              zIndex: 999,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: theme.black,
              opacity: 0.65,
            })}
          />
          <div
            className={css({
              position: 'absolute',
              zIndex: 1000,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: theme.spacing.lg,
              boxShadow: theme.boxShadow.base.toString,
              backgroundColor: theme.palette.primary.base,
              ...theme.heading[3],
              color: theme.white,
              textAlign: 'center',
            })}
          >
            Durchgang nicht abgeschlossen!
          </div>
        </>
      )}

      <Board
        fields={fields}
        activeArea={activeArea}
        activeColor={!activePlacements.length ? 'primary' : 'secondary'}
        loading={loading}
        jobProps={(placement) => {
          const field = fields?.find(({ id }) => id === placement.id);
          const active = activePlacements.find((p) => p?.id === placement.id);
          const state = field?.excluding_question?.id
            ? 'disabled'
            : !activePlacements.length
            ? undefined
            : active
            ? 'active'
            : 'disabled';
          const inActiveArea =
            activeArea?.top ||
            (0 <= placement.y && activeArea?.bottom) ||
            (0 >= placement.y && activeArea?.left) ||
            (0 <= placement.x && activeArea?.right) ||
            0 >= placement.x;
          const hide =
            !!inActiveArea &&
            ((activePlacements.length && !active) ||
              (!!field && !!field.excluding_question?.id));

          return {
            state,
            opacity: hide ? 0.5 : 1,
            // showContent: !hide,
          };
        }}
      />
      <Sidebar disabledNext>
        <Info
          title="Ergebnisse"
          className={css({ flexGrow: 0, flexShrink: 0 })}
        >
          <ul
            className={css({
              listStyleType: 'square',
              paddingLeft: theme.spacing.md,
              margin: 0,
            })}
          >
            <li>
              <span className={css({ fontWeight: 'bold' })}>
                {placements?.length}
              </span>{' '}
              im Feld platzierten Berufe, davon...
            </li>
            <ul
              className={css({
                listStyleType: 'square',
                paddingLeft: theme.spacing.md,
                margin: 0,
              })}
            >
              <li className={css({ marginTop: theme.spacing.xxs })}>
                <span
                  className={css({
                    fontWeight: 'bold',
                    color: theme.palette.primary.base,
                  })}
                >
                  {includedPlacements?.length}
                </span>{' '}
                Berufe im Aspirationsfeld
              </li>
              {activeRiasec && (
                <li className={css({ marginTop: theme.spacing.xxs })}>
                  <span
                    className={css({
                      fontWeight: 'bold',
                      color: activePlacements.length
                        ? theme.palette.secondary[9]
                        : theme.palette.primary.base,
                    })}
                  >
                    {activePlacements.length}
                  </span>{' '}
                  Berufe in "<i>{riasecTitle[activeRiasec]}</i>"
                </li>
              )}
            </ul>
          </ul>
        </Info>

        <div className={css({ flexGrow: 1 })} />

        <div>
          {riasecArr.map((key) => {
            const active = activeRiasec === key;

            return (
              <ProgressBar
                key={key}
                progress={
                  includedPlacements?.length &&
                  includedRiasecIds[key].length + excludedRiasecIds[key].length
                    ? (1 -
                        excludedRiasecIds[key].length /
                          (includedRiasecIds[key].length +
                            excludedRiasecIds[key].length)) *
                      100
                    : 0
                }
                active={active}
                onClick={() => setActiveRiasec(active ? undefined : key)}
              >
                {riasecTitle[key]}
              </ProgressBar>
            );
          })}
        </div>

        {pathname !== '/admin/screenshot' && (
          <>
            <div className={css({ flexGrow: 1 })} />

            <Info>
              Trage hier deine E-Mail-Adresse ein, um ein PDF mit den
              Ergebnissen zu erhalten.
            </Info>

            <Email id={id} code={user?.name} valid_until={user?.valid_until} />
          </>
        )}
      </Sidebar>
    </div>
  );
}
