import { Content } from 'pdfmake/interfaces';
import { getIncludedPlacements } from '../auswertung';

const riasecArr = ['r', 'i', 'a', 's', 'e', 'c'] as const;

type PlacementsType =
  | {
      id: string;
      x: number;
      y: number;
      job: {
        id: string;
        name: string;
        description?: string | null | undefined;
        r: number;
        i: number;
        a: number;
        s: number;
        e: number;
        c: number;
      };
      excluding_question?:
        | {
            id: string;
          }
        | null
        | undefined;
    }[]
  | undefined;

type QuestionnareType = {
  question_0?: number;
  question_1?: number;
  question_2?: number;
  question_3?: number;
  question_4?: number;
  question_5?: number;
  question_6?: number;
  question_7?: number;
  question_8?: number;
  question_9?: number;
  question_10?: number;
  question_11?: number;
  question_12?: number;
  question_13?: number;
  question_14?: number;
  question_15?: number;
  question_16?: number;
  question_17?: number;
  [key: string]: string | number | undefined;
};

export default function getRiasec(
  placements: PlacementsType,
  questionnare?: QuestionnareType,
): Array<Content> {
  const { includedPlacements, includedRiasecIds, excludedRiasecIds } =
    getIncludedPlacements(placements);
  const body: Array<Array<{ text: string; style?: string } | string>> = [
    [
      { text: '', style: 'tableHeader' },
      {
        text: 'Handwerklich-technisch',
        style: 'tableHeader',
      },
      {
        text: 'Wissenschaftlich-forschend',
        style: 'tableHeader',
      },
      {
        text: 'Künstlerisch',
        style: 'tableHeader',
      },
      {
        text: 'Sozial',
        style: 'tableHeader',
      },
      {
        text: 'Unternehmerisch',
        style: 'tableHeader',
      },
      {
        text: 'Ordnend-verwaltend',
        style: 'tableHeader',
      },
    ],
  ];

  if (questionnare)
    body.push([
      {
        text: 'Fragebogen',
        style: 'tableHeader',
      },
      `${(
        (((questionnare?.['question_0'] || 0) +
          (questionnare?.['question_6'] || 0) +
          (questionnare?.['question_12'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
      `${(
        (((questionnare?.['question_1'] || 0) +
          (questionnare?.['question_7'] || 0) +
          (questionnare?.['question_13'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
      `${(
        (((questionnare?.['question_2'] || 0) +
          (questionnare?.['question_8'] || 0) +
          (questionnare?.['question_14'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
      `${(
        (((questionnare?.['question_3'] || 0) +
          (questionnare?.['question_9'] || 0) +
          (questionnare?.['question_15'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
      `${(
        (((questionnare?.['question_4'] || 0) +
          (questionnare?.['question_10'] || 0) +
          (questionnare?.['question_16'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
      `${(
        (((questionnare?.['question_5'] || 0) +
          (questionnare?.['question_11'] || 0) +
          (questionnare?.['question_17'] || 0)) /
          15) *
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')} %`,
    ]);

  body.push([
    {
      text: 'Ibea',
      style: 'tableHeader',
    },
    ...riasecArr?.map(
      (key) =>
        `${(includedPlacements?.length &&
        includedRiasecIds[key].length + excludedRiasecIds[key].length
          ? (1 -
              excludedRiasecIds[key].length /
                (includedRiasecIds[key].length +
                  excludedRiasecIds[key].length)) *
            100
          : 0
        )
          .toFixed(2)
          .toString()
          .replace('.', ',')} %`,
    ),
  ]);

  return [
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', '*', '*', '*', '*', '*'],
        body,
      },
      layout: 'lightHorizontalLines',
    },
  ];
}
