import {
  AuswertungQuery,
  AuswertungQueryVariables,
  useAuswertungQuery,
} from '../autogenerated';
import { QueryResult } from '@apollo/client';
import gql from 'graphql-tag';

gql`
  query auswertung($id: uuid!) {
    repetition(where: { id: { _eq: $id } }) {
      completed_at
      locked_at
      questionnare
      user {
        id
        name
        valid_until
        user_jobs {
          id
          job_id
        }
      }
      placements(
        order_by: { dropped_at: asc }
        where: { _and: { x: { _gte: 0 }, y: { _gte: 0 } } }
      ) {
        id
        x
        y
        job {
          id
          name
          description
          r
          i
          a
          s
          e
          c
        }
        excluding_question {
          id
        }
      }
    }
  }
`;

export type AuswertungType = {
  completed_at?: string;
  locked_at?: string;
  questionnare?: {
    question_0?: number;
    question_1?: number;
    question_2?: number;
    question_3?: number;
    question_4?: number;
    question_5?: number;
    question_6?: number;
    question_7?: number;
    question_8?: number;
    question_9?: number;
    question_10?: number;
    question_11?: number;
    question_12?: number;
    question_13?: number;
    question_14?: number;
    question_15?: number;
    question_16?: number;
    question_17?: number;
    [key: string]: string | number | undefined;
  };
  user?: {
    id?: string;
    name?: string;
    valid_until?: string;
    user_jobs?: Array<{
      id: string;
      job_id: string;
    }>;
  };
  placements?: Array<{
    id: string;
    x: number;
    y: number;
    job: {
      id: string;
      name: string;
      description?: string | null;
      r: number;
      i: number;
      a: number;
      s: number;
      e: number;
      c: number;
    };
    excluding_question?: {
      id: string;
    } | null;
  }>;
};

export function useAuswertung(
  id: string | string[],
): [
  Array<AuswertungType>,
  QueryResult<AuswertungQuery, AuswertungQueryVariables>,
] {
  const result = useAuswertungQuery({ variables: { id } });

  return [result.data?.repetition || [], result];
}
