import {
  DeleteQuestionMutation,
  DeleteQuestionMutationVariables,
  InsertQuestionsMutation,
  InsertQuestionsMutationVariables,
  useDeleteQuestionMutation,
  useInsertQuestionsMutation,
} from '../../autogenerated';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import gql from 'graphql-tag';

gql`
  mutation insert_questions($objects: [question_insert_input!]!) {
    insert_question(
      on_conflict: {
        constraint: question_pkey
        update_columns: [active, text, yes, no, order]
      }
      objects: $objects
    ) {
      returning {
        id
      }
    }
  }
`;

gql`
  mutation delete_question($id: uuid!) {
    update_placement(
      where: { excluding_question_id: { _eq: $id } }
      _set: { excluding_question_id: null }
    ) {
      returning {
        id
      }
    }
    delete_question(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export function useAdminQuestions(): [
  (
    options?: MutationFunctionOptions<
      InsertQuestionsMutation,
      InsertQuestionsMutationVariables
    >,
  ) => Promise<FetchResult<InsertQuestionsMutation>>,
  (
    options?: MutationFunctionOptions<
      DeleteQuestionMutation,
      DeleteQuestionMutationVariables
    >,
  ) => Promise<FetchResult<DeleteQuestionMutation>>,
  boolean,
] {
  const [insertQuestions, { loading: iLoading }] = useInsertQuestionsMutation();
  const [deleteQuestions, { loading: dLoading }] = useDeleteQuestionMutation();

  return [insertQuestions, deleteQuestions, iLoading || dLoading];
}
