import { Layout } from './layout';
import { Modal } from './modal';
import React from 'react';

interface IPage {
  children: React.ReactNode | Array<React.ReactNode>;
  title: string;
  step?: number;
  question?: string | React.ReactNode | null;
  image?: string;
  audio?: string;
  full?: boolean;
}

export function Page({
  children,
  title,
  step,
  question,
  image,
  audio,
  full,
}: IPage) {
  const [hideModal, setHideModal] = React.useState<boolean>(false);
  const onClose = React.useCallback(() => setHideModal(true), []);

  React.useEffect(() => {
    document.title = title ? `IbeA - ${title}` : 'IbeA';
  }, [title]);
  React.useEffect(() => {
    setHideModal(false);
  }, [step]);

  return (
    <Layout full={full}>
      <Modal
        open={!!question && !hideModal}
        onClose={onClose}
        title={
          step ? (
            <span>
              <b>Schritt {step}</b>: {title}
            </span>
          ) : (
            title
          )
        }
        image={image ? image : ''}
        audio={audio}
      >
        {question}
      </Modal>

      {children}
    </Layout>
  );
}
