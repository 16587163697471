import { Col, Row } from 'antd';
import { Hint } from './hint';
import { useFela } from '../theme';
import { useLocation } from 'react-router-dom';
import React from 'react';

interface ILayout {
  children: React.ReactNode;
  full?: boolean;
}

export function Layout({ children, full }: ILayout) {
  const { css, theme } = useFela();
  const { pathname } = useLocation();

  const content = (
    <>
      {children}

      <div
        className={css({
          fontSize: theme.font.sm.fontSize,
          backgroundColor: theme.palette.primary[1],
          padding: theme.spacing.md,
          maxHeight: 42,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <a
          href="https://www.abpaed.tu-darmstadt.de/bp/arbeitsbereich_berufspaedagogik/index.de.jsp"
          target="_blank"
        >
          <b>
            &copy; 2020-2025 by Birgit Ziegler, Technische Universität Darmstadt
          </b>
        </a>
        <a href="/impressum">
          <b>Impressum & Datenschutz</b>
        </a>
      </div>
    </>
  );

  return (
    <Row
      align="middle"
      justify="center"
      className={css({ backgroundImage: `url(/bg.jpg)` })}
    >
      <Col
        xs={24}
        lg={24}
        xl={full ? 24 : 17}
        xxl={full ? 24 : 14}
        className={css({
          boxShadow: theme.boxShadow[3].toString,
          backgroundColor: theme.white,
        })}
      >
        {pathname === '/download' ? (
          content
        ) : (
          <>
            <Hint
              className={css({
                '@media only screen and (min-height: 600px) and (min-width: 600px)':
                  {
                    display: 'none',
                  },
              })}
            >
              Die Auflösung des Anzeigegerätes ist zu gering!
            </Hint>

            <div
              className={css({
                display: 'none',
                '@media only screen and (min-height: 600px) and (min-width: 600px)':
                  {
                    display: 'block',
                    height: '100%',
                  },
              })}
            >
              <Hint
                className={css({
                  ifLandscape: { display: 'none' },
                })}
              >
                Bitte das Anzeigegerätes im Querformat-Modus verwenden!
              </Hint>

              <div
                className={css({
                  height: '100%',
                  overflow: 'auto',
                  ifPortrait: { display: 'none' },
                })}
              >
                {content}
              </div>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
}
