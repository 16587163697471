import { QueryHookOptions, QueryResult } from '@apollo/client';
import { QuestionType } from './use-question';
import {
  QuestionsQuery,
  QuestionsQueryVariables,
  useQuestionsQuery,
} from '../autogenerated';
import gql from 'graphql-tag';

gql`
  query questions {
    question(order_by: [{ order: asc }]) {
      active
      id
      text
      no
      order
      yes
      name
    }
  }
`;

type UseQuestionsReturnDataType = Array<QuestionType>;

export function useQuestions(
  props?: QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>,
): [
  UseQuestionsReturnDataType,
  QueryResult<QuestionsQuery, QuestionsQueryVariables>,
] {
  const result = useQuestionsQuery(props);

  return [result.data?.question || [], result];
}
