import { QueryResult } from '@apollo/client';
import { UserJobsType } from './use-user';
import {
  UsersQuery,
  UsersQueryVariables,
  useUsersQuery,
} from '../autogenerated';
import gql from 'graphql-tag';

gql`
  query users {
    user(order_by: { created_at: desc }) {
      active
      valid_until
      id
      is_admin
      name
      group
      random_mode
      created_at
      user_jobs {
        id
        user_id
        job_id
      }
      repetitions(order_by: { locked_at: desc }) {
        id
        completed_at
      }
    }
  }
`;

export type UsersType = Array<{
  id: string;
  active?: boolean;
  valid_until?: string;
  is_admin?: boolean;
  name?: string;
  group?: string;
  random_mode?: boolean;
  user_jobs?: Array<UserJobsType>;
  repetitions?: Array<{
    id?: string;
    completed_at?: null | string;
  }>;
  created_at?: string;
}>;

export function useUsers(): [
  UsersType,
  QueryResult<UsersQuery, UsersQueryVariables>,
] {
  const result = useUsersQuery();

  return [result.data?.user || [], result];
}
