import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useFela } from '../theme';
import React from 'react';

interface ITitle {
  children?: React.ReactNode | Array<React.ReactNode>;
  className?: string;
  onClose?: () => void;
}

export function Title({ children, className, onClose }: ITitle) {
  const { css, theme } = useFela();

  return (
    <div
      className={`${css({
        fontWeight: 'bold',
        marginBottom: 0,
        color: theme.palette.primary.base,
        textAlign: 'center',
        ellipsis: 'true',
        flexShrink: 0,
        position: 'relative',
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
      })} ${className || ''}`}
    >
      {children}

      {!!onClose && (
        <Button
          type="text"
          shape="circle"
          size="small"
          icon={<CloseOutlined />}
          onClick={onClose}
          className={css({
            position: 'absolute',
            right: 0,
          })}
        />
      )}
    </div>
  );
}
