import { Button } from 'antd';
import { Column } from './column';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useFela } from '../theme';
import { useNav } from '../utils';
import React from 'react';

interface ISidebar {
  children?: React.ReactNode | Array<React.ReactNode>;
  disabledNext?: boolean;
  disabledMsg?: string;
  next?: string;
  onNext?: (onNext?: () => void) => void;
  onPrev?: () => void;
}

export function Sidebar({
  children,
  disabledNext,
  disabledMsg,
  next = 'Weiter',
  onNext: _onNext,
  onPrev: _onPrev,
}: ISidebar) {
  const { css, theme } = useFela();
  const [onPrev, onNext] = useNav();
  const { pathname } = useLocation();

  return (
    <Column>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.black,
          paddingY: theme.spacing.xs,
          borderRadius: theme.border.base.borderRadius,
          boxShadow: theme.boxShadow[3].toString,
          color: theme.white,
          fontSize: 24,
          '> a > img': {
            height: 28,
          },
          '> button': {
            color: theme.white,
          },
        })}
      >
        {pathname !== '/admin/screenshot' ? (
          <Button
            icon={<LeftCircleOutlined />}
            onClick={_onPrev || onPrev}
            disabled={!(_onPrev || onPrev)}
            shape="circle"
            type="link"
            size="large"
          />
        ) : (
          <div />
        )}

        <Link to="/">
          <img
            src="/logo.png"
            alt="logo"
            className={css({ cursor: 'pointer' })}
          />
        </Link>

        {pathname !== '/admin/screenshot' ? (
          <Button
            icon={<RightCircleOutlined />}
            onClick={_onNext ? () => _onNext(onNext) : onNext}
            disabled={!(onNext || _onNext) || disabledNext}
            shape="circle"
            type="link"
            size="large"
          />
        ) : (
          <div />
        )}
      </div>

      {children}

      {!!(onNext || _onNext) && pathname !== '/admin/screenshot' && (
        <Button
          type="primary"
          size="large"
          icon={<RightCircleOutlined />}
          disabled={!onNext || disabledNext}
          onClick={_onNext ? () => _onNext(onNext) : onNext}
          className={css({
            boxShadow: theme.boxShadow[3].toString,
          })}
          title={disabledNext && disabledMsg ? disabledMsg : ''}
        >
          &nbsp;{next}
        </Button>
      )}
    </Column>
  );
}
