import { Button, ButtonProps, message } from 'antd';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { DownloadOutlined } from '@ant-design/icons';
import { now } from 'lodash';
import { useAuswertung, useQuestions } from '../../graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import getBoard from './get-board';
import getPlacements from './get-placements';
import getRiasec from './get-riasec';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type PdfType = {
  id?: string;
  loadingText?: string;
} & ButtonProps;

export function Pdf({
  id = '',
  loadingText = 'Wird generiert...',
  children = <DownloadOutlined />,
  ...rest
}: PdfType) {
  const [loading, setLoading] = useState(false);
  const [board, setBoard] = useState<Array<Content>>([]);

  // get infos from current user
  const [repetitions, { loading: aLoading }] = useAuswertung(id);
  const { user, completed_at, locked_at, questionnare } = repetitions[0] || {};
  const { user_jobs, name } = user || {};
  const { placements } = useMemo(() => {
    const _placements = repetitions?.[0]?.placements?.filter(
      (placement) => placement.x >= 0 && placement.y >= 0,
    );

    return {
      placements: user_jobs?.length
        ? _placements?.filter((placement) =>
            user_jobs.find((user_job) => placement.job.id === user_job.job_id),
          )
        : _placements || [],
    };
  }, [repetitions, user_jobs]);

  // get docDefinition
  const [questions, { loading: qLoading }] = useQuestions();
  useEffect(() => {
    if (board.length) {
      const includedPlacements: typeof placements = [];
      const excludedPlacements: typeof placements = [];

      placements?.forEach((placement) =>
        (!placement.excluding_question?.id
          ? includedPlacements
          : excludedPlacements
        ).push(placement),
      );

      const docDefinition = {
        info: {
          title: 'IbeA Ergebnisse',
          author: 'Birgit Ziegler, Technische Universität Darmstadt',
        },
        header: {
          columns: [
            new Date(completed_at || locked_at || now()).toLocaleDateString(
              'de-DE',
              {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              },
            ),
            { text: name, alignment: 'right' },
          ],
          margin: [4, 4],
        },
        pageOrientation: 'landscape',
        content: [
          ...board,
          {
            text: 'Im Aspirationsfeld',
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 8],
            pageBreak: 'before',
          },
          {
            text: 'Das Aspirationsfeld umfasst die im Feld verbliebenen Berufe (Berufe, die passen könnten).',
            margin: [0, 0, 0, 0],
          },
          {
            text: `In deinem Aspirationsfeld sind insgesamt  ${
              includedPlacements?.length || 0
            } Berufe`,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Anzahl der im Raster platzierten Berufe: ${
              placements?.length || 0
            }`,
            margin: [0, 0, 0, 24],
          },
          ...getPlacements(includedPlacements, questions),
          {
            text: 'Nicht im Aspirationsfeld',
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 8],
            pageBreak: 'before',
          },
          ...getPlacements(excludedPlacements, questions),
          {
            text: 'Profilvergleich: Interessen',
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 8],
            pageBreak: 'before',
          },
          ...getRiasec(placements, questionnare),
        ],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 13,
          },
          tableFooter: {
            bold: true,
          },
          centered: {
            alignment: 'center',
          },
        },
      } as TDocumentDefinitions;

      pdfMake.createPdf(docDefinition).download('ibea_auswertung.pdf');
      setBoard([]);
    }
  }, [board, placements, questions]);

  const onClick = useCallback(async () => {
    setLoading(true);

    if (!completed_at && !locked_at)
      message.warning('Durchgang noch nicht abgeschlossen!');
    else if (id) await (async () => setBoard(await getBoard(id)))();

    setLoading(false);
  }, [id, completed_at, locked_at]);

  return (
    <Button
      type="primary"
      size="large"
      loading={loading}
      disabled={loading || qLoading || aLoading}
      onClick={onClick}
      {...rest}
    >
      {loading ? loadingText : children}
    </Button>
  );
}
