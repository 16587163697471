import { useFela } from '../theme';
import React from 'react';

interface IColumn {
  children?: React.ReactNode | Array<React.ReactNode>;
}

export function Column({ children }: IColumn) {
  const { css, theme } = useFela();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        paddingY: theme.spacing.xxs,
        justifyContent: 'space-between',
        marginRight: theme.spacing.sm,
        width: '100%',
        '> *': {
          marginY: theme.spacing.xs,
        },
        ifExtraLargeUp: {
          flexWidth: 240,
        },
      })}
    >
      {children}
    </div>
  );
}
