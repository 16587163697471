import {
  DeleteUserJobsMutation,
  DeleteUserJobsMutationVariables,
  DeleteUsersMutation,
  DeleteUsersMutationVariables,
  DeleteUsersPlacementsMutation,
  DeleteUsersPlacementsMutationVariables,
  InsertUsersMutation,
  InsertUsersMutationVariables,
  SetNextRepetitionsMutation,
  SetNextRepetitionsMutationVariables,
  useDeleteUserJobsMutation,
  useDeleteUsersMutation,
  useDeleteUsersPlacementsMutation,
  useInsertUsersMutation,
  useResetCurrentRepetitionMutation,
  useSetNextRepetitionsMutation,
} from '../../autogenerated';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { useCallback } from 'react';
import gql from 'graphql-tag';

gql`
  mutation insert_users($objects: [user_insert_input!]!) {
    insert_user(
      on_conflict: {
        constraint: user_pkey
        update_columns: [
          active
          valid_until
          is_admin
          name
          group
          random_mode
        ]
      }
      objects: $objects
    ) {
      returning {
        id
        name
      }
    }
  }
`;

gql`
  mutation delete_users($user_ids: [uuid!]!) {
    delete_user_jobs(where: { user_id: { _in: $user_ids } }) {
      returning {
        id
      }
    }
    delete_placement(where: { repetition: { user_id: { _in: $user_ids } } }) {
      returning {
        id
      }
    }
    delete_repetition(where: { user_id: { _in: $user_ids } }) {
      returning {
        id
      }
    }
    delete_user(where: { id: { _in: $user_ids } }) {
      returning {
        id
      }
    }
  }
`;

gql`
  mutation delete_users_placements($user_ids: [uuid!]!) {
    delete_placement(
      where: {
        repetition: {
          user_id: { _in: $user_ids }
          locked_at: { _is_null: true }
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

gql`
  mutation reset_current_repetition($user_ids: [uuid!]!) {
    update_repetition(
      where: { user_id: { _in: $user_ids }, locked_at: { _is_null: true } }
      _set: { completed_at: null }
    ) {
      returning {
        id
        completed_at
      }
    }
  }
`;

gql`
  mutation set_next_repetitions($user_ids: [uuid!]!) {
    update_repetition(
      where: { user_id: { _in: $user_ids }, locked_at: { _is_null: true } }
      _set: { locked_at: "now()" }
    ) {
      returning {
        id
        locked_at
      }
    }
  }
`;

gql`
  mutation delete_user_jobs($user_ids: [uuid!]!) {
    delete_user_jobs(where: { user_id: { _in: $user_ids } }) {
      returning {
        id
      }
    }
  }
`;

export function useAdminUsers(): {
  insertUsers: (
    options?: MutationFunctionOptions<
      InsertUsersMutation,
      InsertUsersMutationVariables
    >,
  ) => Promise<FetchResult<InsertUsersMutation>>;
  deleteUsers: (
    options?: MutationFunctionOptions<
      DeleteUsersMutation,
      DeleteUsersMutationVariables
    >,
  ) => Promise<FetchResult<DeleteUsersMutation>>;
  resetUsers: (
    options?: MutationFunctionOptions<
      DeleteUsersPlacementsMutation,
      DeleteUsersPlacementsMutationVariables
    >,
    shouldDeletePlacements?: boolean,
  ) => Promise<FetchResult<DeleteUsersPlacementsMutation>>;
  setNextRepetitions: (
    options?: MutationFunctionOptions<
      SetNextRepetitionsMutation,
      SetNextRepetitionsMutationVariables
    >,
  ) => Promise<FetchResult<SetNextRepetitionsMutation>>;
  deleteUsersJobs: (
    options?: MutationFunctionOptions<
      DeleteUserJobsMutation,
      DeleteUserJobsMutationVariables
    >,
  ) => Promise<FetchResult<DeleteUserJobsMutation>>;
  loading: boolean;
} {
  const [insertUsers, { loading: iLoading }] = useInsertUsersMutation();
  const [deleteUsers, { loading: dLoading }] = useDeleteUsersMutation();

  const [deletePlacements, { loading: dPLoading }] =
    useDeleteUsersPlacementsMutation();
  const [resetRepetition, { loading: rPLoading }] =
    useResetCurrentRepetitionMutation();
  const resetUsers = useCallback(
    (
      options?: MutationFunctionOptions<
        SetNextRepetitionsMutation,
        SetNextRepetitionsMutationVariables
      >,
      shouldDeletePlacements?: boolean,
    ) => {
      if (shouldDeletePlacements) deletePlacements(options);

      return resetRepetition(options);
    },
    [deletePlacements, resetRepetition],
  );

  const [setNextRepetitions, { loading: rLoading }] =
    useSetNextRepetitionsMutation();

  const [deleteUsersJobs] = useDeleteUserJobsMutation();

  return {
    insertUsers,
    deleteUsers,
    resetUsers,
    setNextRepetitions,
    deleteUsersJobs,
    loading: iLoading || dLoading || dPLoading || rPLoading || rLoading,
  };
}
