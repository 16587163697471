import { QueryResult } from '@apollo/client';
import { UserQuery, UserQueryVariables, useUserQuery } from '../autogenerated';
import { useSession } from '..';
import gql from 'graphql-tag';

gql`
  query user($id: uuid) {
    user(where: { id: { _eq: $id } }) {
      active
      valid_until
      id
      is_admin
      name
      group
      random_mode
      created_at
      user_jobs {
        id
        user_id
        job_id
      }
      repetitions(order_by: { locked_at: desc }) {
        id
        completed_at
        placements(order_by: { dropped_at: asc }) {
          id
          x
          y
          job_id
          job {
            active
            description
            id
            name
            r
            i
            a
            s
            e
            c
          }
          excluding_question {
            active
            id
            text
            no
            order
            yes
            name
          }
        }
      }
    }
  }
`;

export type UserRepetitionType = {
  id?: string;
  completed_at?: null | string;
  placements?: Array<{
    id: string;
    x: number;
    y: number;
    job_id: string;
    job: {
      active?: boolean | null;
      description?: string | null;
      id: string;
      name: string;
      r: number;
      i: number;
      a: number;
      s: number;
      e: number;
      c: number;
    };
    excluding_question?: {
      active?: boolean | null;
      id: string;
      text?: string | null;
      no?: string | null;
      order?: number | null;
      yes?: string | null;
    } | null;
  }>;
};
export type UserJobsType = {
  id: string;
  user_id: string;
  job_id: string;
};
export type UserType = {
  id: string;
  active?: boolean;
  valid_until?: string;
  is_admin?: boolean;
  name?: string;
  group?: string;
  random_mode?: boolean;
  user_jobs?: Array<UserJobsType>;
  repetitions?: Array<UserRepetitionType>;
  created_at?: string;
};

export function useUser(): [
  UserType | undefined,
  QueryResult<UserQuery, UserQueryVariables>,
] {
  const { session } = useSession();
  const result = useUserQuery({ variables: { id: session.id } });

  return [result.data?.user[0], result];
}
