import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  a: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  c: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  e: Scalars['Int'];
  i: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  placements: Array<Placement>;
  /** An aggregate relationship */
  placements_aggregate: PlacementAggregate;
  r: Scalars['Int'];
  s: Scalars['Int'];
  /** An array relationship */
  user_jobs: Array<UserJobs>;
  /** An aggregate relationship */
  user_jobs_aggregate: UserJobsAggregate;
};


/** columns and relationships of "job" */
export type JobPlacementsArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


/** columns and relationships of "job" */
export type JobPlacementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


/** columns and relationships of "job" */
export type JobUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


/** columns and relationships of "job" */
export type JobUserJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};

/** aggregated selection of "job" */
export type JobAggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<JobAggregateFields>;
  nodes: Array<Job>;
};

/** aggregate fields of "job" */
export type JobAggregateFields = {
  __typename?: 'job_aggregate_fields';
  avg?: Maybe<JobAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<JobMaxFields>;
  min?: Maybe<JobMinFields>;
  stddev?: Maybe<JobStddevFields>;
  stddev_pop?: Maybe<JobStddevPopFields>;
  stddev_samp?: Maybe<JobStddevSampFields>;
  sum?: Maybe<JobSumFields>;
  var_pop?: Maybe<JobVarPopFields>;
  var_samp?: Maybe<JobVarSampFields>;
  variance?: Maybe<JobVarianceFields>;
};


/** aggregate fields of "job" */
export type JobAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<JobSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type JobAvgFields = {
  __typename?: 'job_avg_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type JobBoolExp = {
  _and?: InputMaybe<Array<JobBoolExp>>;
  _not?: InputMaybe<JobBoolExp>;
  _or?: InputMaybe<Array<JobBoolExp>>;
  a?: InputMaybe<IntComparisonExp>;
  active?: InputMaybe<BooleanComparisonExp>;
  c?: InputMaybe<IntComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  e?: InputMaybe<IntComparisonExp>;
  i?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  placements?: InputMaybe<PlacementBoolExp>;
  placements_aggregate?: InputMaybe<PlacementAggregateBoolExp>;
  r?: InputMaybe<IntComparisonExp>;
  s?: InputMaybe<IntComparisonExp>;
  user_jobs?: InputMaybe<UserJobsBoolExp>;
  user_jobs_aggregate?: InputMaybe<UserJobsAggregateBoolExp>;
};

/** unique or primary key constraints on table "job" */
export enum JobConstraint {
  /** unique or primary key constraint on columns "name" */
  JobNameKey = 'job_name_key',
  /** unique or primary key constraint on columns "id" */
  JobsPkey = 'jobs_pkey'
}

/** input type for incrementing numeric columns in table "job" */
export type JobIncInput = {
  a?: InputMaybe<Scalars['Int']>;
  c?: InputMaybe<Scalars['Int']>;
  e?: InputMaybe<Scalars['Int']>;
  i?: InputMaybe<Scalars['Int']>;
  r?: InputMaybe<Scalars['Int']>;
  s?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job" */
export type JobInsertInput = {
  a?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  c?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  e?: InputMaybe<Scalars['Int']>;
  i?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  placements?: InputMaybe<PlacementArrRelInsertInput>;
  r?: InputMaybe<Scalars['Int']>;
  s?: InputMaybe<Scalars['Int']>;
  user_jobs?: InputMaybe<UserJobsArrRelInsertInput>;
};

/** aggregate max on columns */
export type JobMaxFields = {
  __typename?: 'job_max_fields';
  a?: Maybe<Scalars['Int']>;
  c?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  e?: Maybe<Scalars['Int']>;
  i?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  r?: Maybe<Scalars['Int']>;
  s?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type JobMinFields = {
  __typename?: 'job_min_fields';
  a?: Maybe<Scalars['Int']>;
  c?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  e?: Maybe<Scalars['Int']>;
  i?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  r?: Maybe<Scalars['Int']>;
  s?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "job" */
export type JobMutationResponse = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** input type for inserting object relation for remote table "job" */
export type JobObjRelInsertInput = {
  data: JobInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<JobOnConflict>;
};

/** on_conflict condition type for table "job" */
export type JobOnConflict = {
  constraint: JobConstraint;
  update_columns?: Array<JobUpdateColumn>;
  where?: InputMaybe<JobBoolExp>;
};

/** Ordering options when selecting data from "job". */
export type JobOrderBy = {
  a?: InputMaybe<OrderBy>;
  active?: InputMaybe<OrderBy>;
  c?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  e?: InputMaybe<OrderBy>;
  i?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  placements_aggregate?: InputMaybe<PlacementAggregateOrderBy>;
  r?: InputMaybe<OrderBy>;
  s?: InputMaybe<OrderBy>;
  user_jobs_aggregate?: InputMaybe<UserJobsAggregateOrderBy>;
};

/** primary key columns input for table: job */
export type JobPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "job" */
export enum JobSelectColumn {
  /** column name */
  A = 'a',
  /** column name */
  Active = 'active',
  /** column name */
  C = 'c',
  /** column name */
  Description = 'description',
  /** column name */
  E = 'e',
  /** column name */
  I = 'i',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  R = 'r',
  /** column name */
  S = 's'
}

/** input type for updating data in table "job" */
export type JobSetInput = {
  a?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  c?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  e?: InputMaybe<Scalars['Int']>;
  i?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  r?: InputMaybe<Scalars['Int']>;
  s?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type JobStddevFields = {
  __typename?: 'job_stddev_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type JobStddevPopFields = {
  __typename?: 'job_stddev_pop_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type JobStddevSampFields = {
  __typename?: 'job_stddev_samp_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "job" */
export type JobStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: JobStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type JobStreamCursorValueInput = {
  a?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  c?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  e?: InputMaybe<Scalars['Int']>;
  i?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  r?: InputMaybe<Scalars['Int']>;
  s?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type JobSumFields = {
  __typename?: 'job_sum_fields';
  a?: Maybe<Scalars['Int']>;
  c?: Maybe<Scalars['Int']>;
  e?: Maybe<Scalars['Int']>;
  i?: Maybe<Scalars['Int']>;
  r?: Maybe<Scalars['Int']>;
  s?: Maybe<Scalars['Int']>;
};

/** update columns of table "job" */
export enum JobUpdateColumn {
  /** column name */
  A = 'a',
  /** column name */
  Active = 'active',
  /** column name */
  C = 'c',
  /** column name */
  Description = 'description',
  /** column name */
  E = 'e',
  /** column name */
  I = 'i',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  R = 'r',
  /** column name */
  S = 's'
}

export type JobUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobSetInput>;
  where: JobBoolExp;
};

/** aggregate var_pop on columns */
export type JobVarPopFields = {
  __typename?: 'job_var_pop_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type JobVarSampFields = {
  __typename?: 'job_var_samp_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type JobVarianceFields = {
  __typename?: 'job_variance_fields';
  a?: Maybe<Scalars['Float']>;
  c?: Maybe<Scalars['Float']>;
  e?: Maybe<Scalars['Float']>;
  i?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "job" */
  delete_job?: Maybe<JobMutationResponse>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "placement" */
  delete_placement?: Maybe<PlacementMutationResponse>;
  /** delete single row from the table: "placement" */
  delete_placement_by_pk?: Maybe<Placement>;
  /** delete data from the table: "question" */
  delete_question?: Maybe<QuestionMutationResponse>;
  /** delete single row from the table: "question" */
  delete_question_by_pk?: Maybe<Question>;
  /** delete data from the table: "repetition" */
  delete_repetition?: Maybe<RepetitionMutationResponse>;
  /** delete single row from the table: "repetition" */
  delete_repetition_by_pk?: Maybe<Repetition>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_jobs" */
  delete_user_jobs?: Maybe<UserJobsMutationResponse>;
  /** delete single row from the table: "user_jobs" */
  delete_user_jobs_by_pk?: Maybe<UserJobs>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<JobMutationResponse>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "placement" */
  insert_placement?: Maybe<PlacementMutationResponse>;
  /** insert a single row into the table: "placement" */
  insert_placement_one?: Maybe<Placement>;
  /** insert data into the table: "question" */
  insert_question?: Maybe<QuestionMutationResponse>;
  /** insert a single row into the table: "question" */
  insert_question_one?: Maybe<Question>;
  /** insert data into the table: "repetition" */
  insert_repetition?: Maybe<RepetitionMutationResponse>;
  /** insert a single row into the table: "repetition" */
  insert_repetition_one?: Maybe<Repetition>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<UserMutationResponse>;
  /** insert data into the table: "user_jobs" */
  insert_user_jobs?: Maybe<UserJobsMutationResponse>;
  /** insert a single row into the table: "user_jobs" */
  insert_user_jobs_one?: Maybe<UserJobs>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** update data of the table: "job" */
  update_job?: Maybe<JobMutationResponse>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<JobMutationResponse>>>;
  /** update data of the table: "placement" */
  update_placement?: Maybe<PlacementMutationResponse>;
  /** update single row of the table: "placement" */
  update_placement_by_pk?: Maybe<Placement>;
  /** update multiples rows of table: "placement" */
  update_placement_many?: Maybe<Array<Maybe<PlacementMutationResponse>>>;
  /** update data of the table: "question" */
  update_question?: Maybe<QuestionMutationResponse>;
  /** update single row of the table: "question" */
  update_question_by_pk?: Maybe<Question>;
  /** update multiples rows of table: "question" */
  update_question_many?: Maybe<Array<Maybe<QuestionMutationResponse>>>;
  /** update data of the table: "repetition" */
  update_repetition?: Maybe<RepetitionMutationResponse>;
  /** update single row of the table: "repetition" */
  update_repetition_by_pk?: Maybe<Repetition>;
  /** update multiples rows of table: "repetition" */
  update_repetition_many?: Maybe<Array<Maybe<RepetitionMutationResponse>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<UserMutationResponse>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_jobs" */
  update_user_jobs?: Maybe<UserJobsMutationResponse>;
  /** update single row of the table: "user_jobs" */
  update_user_jobs_by_pk?: Maybe<UserJobs>;
  /** update multiples rows of table: "user_jobs" */
  update_user_jobs_many?: Maybe<Array<Maybe<UserJobsMutationResponse>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<UserMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteJobArgs = {
  where: JobBoolExp;
};


/** mutation root */
export type MutationRootDeleteJobByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePlacementArgs = {
  where: PlacementBoolExp;
};


/** mutation root */
export type MutationRootDeletePlacementByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteQuestionArgs = {
  where: QuestionBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteRepetitionArgs = {
  where: RepetitionBoolExp;
};


/** mutation root */
export type MutationRootDeleteRepetitionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserArgs = {
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserJobsArgs = {
  where: UserJobsBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserJobsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootInsertJobArgs = {
  objects: Array<JobInsertInput>;
  on_conflict?: InputMaybe<JobOnConflict>;
};


/** mutation root */
export type MutationRootInsertJobOneArgs = {
  object: JobInsertInput;
  on_conflict?: InputMaybe<JobOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlacementArgs = {
  objects: Array<PlacementInsertInput>;
  on_conflict?: InputMaybe<PlacementOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlacementOneArgs = {
  object: PlacementInsertInput;
  on_conflict?: InputMaybe<PlacementOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionArgs = {
  objects: Array<QuestionInsertInput>;
  on_conflict?: InputMaybe<QuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionOneArgs = {
  object: QuestionInsertInput;
  on_conflict?: InputMaybe<QuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertRepetitionArgs = {
  objects: Array<RepetitionInsertInput>;
  on_conflict?: InputMaybe<RepetitionOnConflict>;
};


/** mutation root */
export type MutationRootInsertRepetitionOneArgs = {
  object: RepetitionInsertInput;
  on_conflict?: InputMaybe<RepetitionOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserJobsArgs = {
  objects: Array<UserJobsInsertInput>;
  on_conflict?: InputMaybe<UserJobsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserJobsOneArgs = {
  object: UserJobsInsertInput;
  on_conflict?: InputMaybe<UserJobsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOneArgs = {
  object: UserInsertInput;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootUpdateJobArgs = {
  _inc?: InputMaybe<JobIncInput>;
  _set?: InputMaybe<JobSetInput>;
  where: JobBoolExp;
};


/** mutation root */
export type MutationRootUpdateJobByPkArgs = {
  _inc?: InputMaybe<JobIncInput>;
  _set?: InputMaybe<JobSetInput>;
  pk_columns: JobPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateJobManyArgs = {
  updates: Array<JobUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlacementArgs = {
  _inc?: InputMaybe<PlacementIncInput>;
  _set?: InputMaybe<PlacementSetInput>;
  where: PlacementBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlacementByPkArgs = {
  _inc?: InputMaybe<PlacementIncInput>;
  _set?: InputMaybe<PlacementSetInput>;
  pk_columns: PlacementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlacementManyArgs = {
  updates: Array<PlacementUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionArgs = {
  _inc?: InputMaybe<QuestionIncInput>;
  _set?: InputMaybe<QuestionSetInput>;
  where: QuestionBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionByPkArgs = {
  _inc?: InputMaybe<QuestionIncInput>;
  _set?: InputMaybe<QuestionSetInput>;
  pk_columns: QuestionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionManyArgs = {
  updates: Array<QuestionUpdates>;
};


/** mutation root */
export type MutationRootUpdateRepetitionArgs = {
  _append?: InputMaybe<RepetitionAppendInput>;
  _delete_at_path?: InputMaybe<RepetitionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RepetitionDeleteElemInput>;
  _delete_key?: InputMaybe<RepetitionDeleteKeyInput>;
  _prepend?: InputMaybe<RepetitionPrependInput>;
  _set?: InputMaybe<RepetitionSetInput>;
  where: RepetitionBoolExp;
};


/** mutation root */
export type MutationRootUpdateRepetitionByPkArgs = {
  _append?: InputMaybe<RepetitionAppendInput>;
  _delete_at_path?: InputMaybe<RepetitionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RepetitionDeleteElemInput>;
  _delete_key?: InputMaybe<RepetitionDeleteKeyInput>;
  _prepend?: InputMaybe<RepetitionPrependInput>;
  _set?: InputMaybe<RepetitionSetInput>;
  pk_columns: RepetitionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRepetitionManyArgs = {
  updates: Array<RepetitionUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserArgs = {
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserByPkArgs = {
  _set?: InputMaybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserJobsArgs = {
  _set?: InputMaybe<UserJobsSetInput>;
  where: UserJobsBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserJobsByPkArgs = {
  _set?: InputMaybe<UserJobsSetInput>;
  pk_columns: UserJobsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserJobsManyArgs = {
  updates: Array<UserJobsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "placement" */
export type Placement = {
  __typename?: 'placement';
  dropped_at: Scalars['timestamptz'];
  /** An object relationship */
  excluding_question?: Maybe<Question>;
  excluding_question_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid'];
  /** An object relationship */
  repetition: Repetition;
  repetition_id: Scalars['uuid'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

/** aggregated selection of "placement" */
export type PlacementAggregate = {
  __typename?: 'placement_aggregate';
  aggregate?: Maybe<PlacementAggregateFields>;
  nodes: Array<Placement>;
};

export type PlacementAggregateBoolExp = {
  count?: InputMaybe<PlacementAggregateBoolExpCount>;
};

export type PlacementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PlacementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlacementBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "placement" */
export type PlacementAggregateFields = {
  __typename?: 'placement_aggregate_fields';
  avg?: Maybe<PlacementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PlacementMaxFields>;
  min?: Maybe<PlacementMinFields>;
  stddev?: Maybe<PlacementStddevFields>;
  stddev_pop?: Maybe<PlacementStddevPopFields>;
  stddev_samp?: Maybe<PlacementStddevSampFields>;
  sum?: Maybe<PlacementSumFields>;
  var_pop?: Maybe<PlacementVarPopFields>;
  var_samp?: Maybe<PlacementVarSampFields>;
  variance?: Maybe<PlacementVarianceFields>;
};


/** aggregate fields of "placement" */
export type PlacementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlacementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "placement" */
export type PlacementAggregateOrderBy = {
  avg?: InputMaybe<PlacementAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlacementMaxOrderBy>;
  min?: InputMaybe<PlacementMinOrderBy>;
  stddev?: InputMaybe<PlacementStddevOrderBy>;
  stddev_pop?: InputMaybe<PlacementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlacementStddevSampOrderBy>;
  sum?: InputMaybe<PlacementSumOrderBy>;
  var_pop?: InputMaybe<PlacementVarPopOrderBy>;
  var_samp?: InputMaybe<PlacementVarSampOrderBy>;
  variance?: InputMaybe<PlacementVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "placement" */
export type PlacementArrRelInsertInput = {
  data: Array<PlacementInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlacementOnConflict>;
};

/** aggregate avg on columns */
export type PlacementAvgFields = {
  __typename?: 'placement_avg_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "placement" */
export type PlacementAvgOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "placement". All fields are combined with a logical 'AND'. */
export type PlacementBoolExp = {
  _and?: InputMaybe<Array<PlacementBoolExp>>;
  _not?: InputMaybe<PlacementBoolExp>;
  _or?: InputMaybe<Array<PlacementBoolExp>>;
  dropped_at?: InputMaybe<TimestamptzComparisonExp>;
  excluding_question?: InputMaybe<QuestionBoolExp>;
  excluding_question_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  job?: InputMaybe<JobBoolExp>;
  job_id?: InputMaybe<UuidComparisonExp>;
  repetition?: InputMaybe<RepetitionBoolExp>;
  repetition_id?: InputMaybe<UuidComparisonExp>;
  x?: InputMaybe<IntComparisonExp>;
  y?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "placement" */
export enum PlacementConstraint {
  /** unique or primary key constraint on columns "id" */
  ClassificationPkey = 'classification_pkey',
  /** unique or primary key constraint on columns "job_id", "repetition_id" */
  PlacementJobIdRepetitionIdKey = 'placement_job_id_repetition_id_key'
}

/** input type for incrementing numeric columns in table "placement" */
export type PlacementIncInput = {
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "placement" */
export type PlacementInsertInput = {
  dropped_at?: InputMaybe<Scalars['timestamptz']>;
  excluding_question?: InputMaybe<QuestionObjRelInsertInput>;
  excluding_question_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<JobObjRelInsertInput>;
  job_id?: InputMaybe<Scalars['uuid']>;
  repetition?: InputMaybe<RepetitionObjRelInsertInput>;
  repetition_id?: InputMaybe<Scalars['uuid']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PlacementMaxFields = {
  __typename?: 'placement_max_fields';
  dropped_at?: Maybe<Scalars['timestamptz']>;
  excluding_question_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  repetition_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "placement" */
export type PlacementMaxOrderBy = {
  dropped_at?: InputMaybe<OrderBy>;
  excluding_question_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  job_id?: InputMaybe<OrderBy>;
  repetition_id?: InputMaybe<OrderBy>;
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlacementMinFields = {
  __typename?: 'placement_min_fields';
  dropped_at?: Maybe<Scalars['timestamptz']>;
  excluding_question_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  repetition_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "placement" */
export type PlacementMinOrderBy = {
  dropped_at?: InputMaybe<OrderBy>;
  excluding_question_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  job_id?: InputMaybe<OrderBy>;
  repetition_id?: InputMaybe<OrderBy>;
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "placement" */
export type PlacementMutationResponse = {
  __typename?: 'placement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Placement>;
};

/** on_conflict condition type for table "placement" */
export type PlacementOnConflict = {
  constraint: PlacementConstraint;
  update_columns?: Array<PlacementUpdateColumn>;
  where?: InputMaybe<PlacementBoolExp>;
};

/** Ordering options when selecting data from "placement". */
export type PlacementOrderBy = {
  dropped_at?: InputMaybe<OrderBy>;
  excluding_question?: InputMaybe<QuestionOrderBy>;
  excluding_question_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  job?: InputMaybe<JobOrderBy>;
  job_id?: InputMaybe<OrderBy>;
  repetition?: InputMaybe<RepetitionOrderBy>;
  repetition_id?: InputMaybe<OrderBy>;
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: placement */
export type PlacementPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "placement" */
export enum PlacementSelectColumn {
  /** column name */
  DroppedAt = 'dropped_at',
  /** column name */
  ExcludingQuestionId = 'excluding_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  RepetitionId = 'repetition_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "placement" */
export type PlacementSetInput = {
  dropped_at?: InputMaybe<Scalars['timestamptz']>;
  excluding_question_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  repetition_id?: InputMaybe<Scalars['uuid']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PlacementStddevFields = {
  __typename?: 'placement_stddev_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "placement" */
export type PlacementStddevOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PlacementStddevPopFields = {
  __typename?: 'placement_stddev_pop_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "placement" */
export type PlacementStddevPopOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PlacementStddevSampFields = {
  __typename?: 'placement_stddev_samp_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "placement" */
export type PlacementStddevSampOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "placement" */
export type PlacementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlacementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlacementStreamCursorValueInput = {
  dropped_at?: InputMaybe<Scalars['timestamptz']>;
  excluding_question_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  repetition_id?: InputMaybe<Scalars['uuid']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type PlacementSumFields = {
  __typename?: 'placement_sum_fields';
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "placement" */
export type PlacementSumOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** update columns of table "placement" */
export enum PlacementUpdateColumn {
  /** column name */
  DroppedAt = 'dropped_at',
  /** column name */
  ExcludingQuestionId = 'excluding_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  RepetitionId = 'repetition_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

export type PlacementUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlacementIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlacementSetInput>;
  where: PlacementBoolExp;
};

/** aggregate var_pop on columns */
export type PlacementVarPopFields = {
  __typename?: 'placement_var_pop_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "placement" */
export type PlacementVarPopOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PlacementVarSampFields = {
  __typename?: 'placement_var_samp_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "placement" */
export type PlacementVarSampOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PlacementVarianceFields = {
  __typename?: 'placement_variance_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "placement" */
export type PlacementVarianceOrderBy = {
  x?: InputMaybe<OrderBy>;
  y?: InputMaybe<OrderBy>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: JobAggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "placement" */
  placement: Array<Placement>;
  /** fetch aggregated fields from the table: "placement" */
  placement_aggregate: PlacementAggregate;
  /** fetch data from the table: "placement" using primary key columns */
  placement_by_pk?: Maybe<Placement>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: QuestionAggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table: "repetition" */
  repetition: Array<Repetition>;
  /** fetch aggregated fields from the table: "repetition" */
  repetition_aggregate: RepetitionAggregate;
  /** fetch data from the table: "repetition" using primary key columns */
  repetition_by_pk?: Maybe<Repetition>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** An array relationship */
  user_jobs: Array<UserJobs>;
  /** An aggregate relationship */
  user_jobs_aggregate: UserJobsAggregate;
  /** fetch data from the table: "user_jobs" using primary key columns */
  user_jobs_by_pk?: Maybe<UserJobs>;
};


export type QueryRootJobArgs = {
  distinct_on?: InputMaybe<Array<JobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobOrderBy>>;
  where?: InputMaybe<JobBoolExp>;
};


export type QueryRootJobAggregateArgs = {
  distinct_on?: InputMaybe<Array<JobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobOrderBy>>;
  where?: InputMaybe<JobBoolExp>;
};


export type QueryRootJobByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPlacementArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


export type QueryRootPlacementAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


export type QueryRootPlacementByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type QueryRootQuestionAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type QueryRootQuestionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootRepetitionArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


export type QueryRootRepetitionAggregateArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


export type QueryRootRepetitionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


export type QueryRootUserJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


export type QueryRootUserJobsByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "question" */
export type Question = {
  __typename?: 'question';
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  no?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** An array relationship */
  placements: Array<Placement>;
  /** An aggregate relationship */
  placements_aggregate: PlacementAggregate;
  text?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
};


/** columns and relationships of "question" */
export type QuestionPlacementsArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


/** columns and relationships of "question" */
export type QuestionPlacementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};

/** aggregated selection of "question" */
export type QuestionAggregate = {
  __typename?: 'question_aggregate';
  aggregate?: Maybe<QuestionAggregateFields>;
  nodes: Array<Question>;
};

/** aggregate fields of "question" */
export type QuestionAggregateFields = {
  __typename?: 'question_aggregate_fields';
  avg?: Maybe<QuestionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<QuestionMaxFields>;
  min?: Maybe<QuestionMinFields>;
  stddev?: Maybe<QuestionStddevFields>;
  stddev_pop?: Maybe<QuestionStddevPopFields>;
  stddev_samp?: Maybe<QuestionStddevSampFields>;
  sum?: Maybe<QuestionSumFields>;
  var_pop?: Maybe<QuestionVarPopFields>;
  var_samp?: Maybe<QuestionVarSampFields>;
  variance?: Maybe<QuestionVarianceFields>;
};


/** aggregate fields of "question" */
export type QuestionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type QuestionAvgFields = {
  __typename?: 'question_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export type QuestionBoolExp = {
  _and?: InputMaybe<Array<QuestionBoolExp>>;
  _not?: InputMaybe<QuestionBoolExp>;
  _or?: InputMaybe<Array<QuestionBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  no?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  placements?: InputMaybe<PlacementBoolExp>;
  placements_aggregate?: InputMaybe<PlacementAggregateBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
  yes?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "question" */
export enum QuestionConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionPkey = 'question_pkey'
}

/** input type for incrementing numeric columns in table "question" */
export type QuestionIncInput = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "question" */
export type QuestionInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  placements?: InputMaybe<PlacementArrRelInsertInput>;
  text?: InputMaybe<Scalars['String']>;
  yes?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QuestionMaxFields = {
  __typename?: 'question_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type QuestionMinFields = {
  __typename?: 'question_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question" */
export type QuestionMutationResponse = {
  __typename?: 'question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question>;
};

/** input type for inserting object relation for remote table "question" */
export type QuestionObjRelInsertInput = {
  data: QuestionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionOnConflict>;
};

/** on_conflict condition type for table "question" */
export type QuestionOnConflict = {
  constraint: QuestionConstraint;
  update_columns?: Array<QuestionUpdateColumn>;
  where?: InputMaybe<QuestionBoolExp>;
};

/** Ordering options when selecting data from "question". */
export type QuestionOrderBy = {
  active?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  no?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  placements_aggregate?: InputMaybe<PlacementAggregateOrderBy>;
  text?: InputMaybe<OrderBy>;
  yes?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: question */
export type QuestionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "question" */
export enum QuestionSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  No = 'no',
  /** column name */
  Order = 'order',
  /** column name */
  Text = 'text',
  /** column name */
  Yes = 'yes'
}

/** input type for updating data in table "question" */
export type QuestionSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  yes?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type QuestionStddevFields = {
  __typename?: 'question_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type QuestionStddevPopFields = {
  __typename?: 'question_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type QuestionStddevSampFields = {
  __typename?: 'question_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "question" */
export type QuestionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  yes?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type QuestionSumFields = {
  __typename?: 'question_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "question" */
export enum QuestionUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  No = 'no',
  /** column name */
  Order = 'order',
  /** column name */
  Text = 'text',
  /** column name */
  Yes = 'yes'
}

export type QuestionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QuestionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionSetInput>;
  where: QuestionBoolExp;
};

/** aggregate var_pop on columns */
export type QuestionVarPopFields = {
  __typename?: 'question_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type QuestionVarSampFields = {
  __typename?: 'question_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type QuestionVarianceFields = {
  __typename?: 'question_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "repetition" */
export type Repetition = {
  __typename?: 'repetition';
  completed_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  locked_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  placements: Array<Placement>;
  /** An aggregate relationship */
  placements_aggregate: PlacementAggregate;
  questionnare?: Maybe<Scalars['jsonb']>;
  show_questions: Scalars['Boolean'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "repetition" */
export type RepetitionPlacementsArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


/** columns and relationships of "repetition" */
export type RepetitionPlacementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


/** columns and relationships of "repetition" */
export type RepetitionQuestionnareArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "repetition" */
export type RepetitionAggregate = {
  __typename?: 'repetition_aggregate';
  aggregate?: Maybe<RepetitionAggregateFields>;
  nodes: Array<Repetition>;
};

export type RepetitionAggregateBoolExp = {
  bool_and?: InputMaybe<RepetitionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<RepetitionAggregateBoolExpBoolOr>;
  count?: InputMaybe<RepetitionAggregateBoolExpCount>;
};

export type RepetitionAggregateBoolExpBoolAnd = {
  arguments: RepetitionSelectColumnRepetitionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RepetitionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type RepetitionAggregateBoolExpBoolOr = {
  arguments: RepetitionSelectColumnRepetitionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RepetitionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type RepetitionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RepetitionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RepetitionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "repetition" */
export type RepetitionAggregateFields = {
  __typename?: 'repetition_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RepetitionMaxFields>;
  min?: Maybe<RepetitionMinFields>;
};


/** aggregate fields of "repetition" */
export type RepetitionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RepetitionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "repetition" */
export type RepetitionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RepetitionMaxOrderBy>;
  min?: InputMaybe<RepetitionMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RepetitionAppendInput = {
  questionnare?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "repetition" */
export type RepetitionArrRelInsertInput = {
  data: Array<RepetitionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RepetitionOnConflict>;
};

/** Boolean expression to filter rows from the table "repetition". All fields are combined with a logical 'AND'. */
export type RepetitionBoolExp = {
  _and?: InputMaybe<Array<RepetitionBoolExp>>;
  _not?: InputMaybe<RepetitionBoolExp>;
  _or?: InputMaybe<Array<RepetitionBoolExp>>;
  completed_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  locked_at?: InputMaybe<TimestamptzComparisonExp>;
  placements?: InputMaybe<PlacementBoolExp>;
  placements_aggregate?: InputMaybe<PlacementAggregateBoolExp>;
  questionnare?: InputMaybe<JsonbComparisonExp>;
  show_questions?: InputMaybe<BooleanComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "repetition" */
export enum RepetitionConstraint {
  /** unique or primary key constraint on columns "id" */
  RepetitionPkey = 'repetition_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RepetitionDeleteAtPathInput = {
  questionnare?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RepetitionDeleteElemInput = {
  questionnare?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RepetitionDeleteKeyInput = {
  questionnare?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "repetition" */
export type RepetitionInsertInput = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locked_at?: InputMaybe<Scalars['timestamptz']>;
  placements?: InputMaybe<PlacementArrRelInsertInput>;
  questionnare?: InputMaybe<Scalars['jsonb']>;
  show_questions?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RepetitionMaxFields = {
  __typename?: 'repetition_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "repetition" */
export type RepetitionMaxOrderBy = {
  completed_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locked_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RepetitionMinFields = {
  __typename?: 'repetition_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "repetition" */
export type RepetitionMinOrderBy = {
  completed_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locked_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "repetition" */
export type RepetitionMutationResponse = {
  __typename?: 'repetition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Repetition>;
};

/** input type for inserting object relation for remote table "repetition" */
export type RepetitionObjRelInsertInput = {
  data: RepetitionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RepetitionOnConflict>;
};

/** on_conflict condition type for table "repetition" */
export type RepetitionOnConflict = {
  constraint: RepetitionConstraint;
  update_columns?: Array<RepetitionUpdateColumn>;
  where?: InputMaybe<RepetitionBoolExp>;
};

/** Ordering options when selecting data from "repetition". */
export type RepetitionOrderBy = {
  completed_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locked_at?: InputMaybe<OrderBy>;
  placements_aggregate?: InputMaybe<PlacementAggregateOrderBy>;
  questionnare?: InputMaybe<OrderBy>;
  show_questions?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: repetition */
export type RepetitionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RepetitionPrependInput = {
  questionnare?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "repetition" */
export enum RepetitionSelectColumn {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Questionnare = 'questionnare',
  /** column name */
  ShowQuestions = 'show_questions',
  /** column name */
  UserId = 'user_id'
}

/** select "repetition_aggregate_bool_exp_bool_and_arguments_columns" columns of table "repetition" */
export enum RepetitionSelectColumnRepetitionAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  ShowQuestions = 'show_questions'
}

/** select "repetition_aggregate_bool_exp_bool_or_arguments_columns" columns of table "repetition" */
export enum RepetitionSelectColumnRepetitionAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  ShowQuestions = 'show_questions'
}

/** input type for updating data in table "repetition" */
export type RepetitionSetInput = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locked_at?: InputMaybe<Scalars['timestamptz']>;
  questionnare?: InputMaybe<Scalars['jsonb']>;
  show_questions?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "repetition" */
export type RepetitionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RepetitionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RepetitionStreamCursorValueInput = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locked_at?: InputMaybe<Scalars['timestamptz']>;
  questionnare?: InputMaybe<Scalars['jsonb']>;
  show_questions?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "repetition" */
export enum RepetitionUpdateColumn {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Questionnare = 'questionnare',
  /** column name */
  ShowQuestions = 'show_questions',
  /** column name */
  UserId = 'user_id'
}

export type RepetitionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RepetitionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RepetitionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RepetitionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RepetitionDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RepetitionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RepetitionSetInput>;
  where: RepetitionBoolExp;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: JobAggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "placement" */
  placement: Array<Placement>;
  /** fetch aggregated fields from the table: "placement" */
  placement_aggregate: PlacementAggregate;
  /** fetch data from the table: "placement" using primary key columns */
  placement_by_pk?: Maybe<Placement>;
  /** fetch data from the table in a streaming manner: "placement" */
  placement_stream: Array<Placement>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: QuestionAggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table in a streaming manner: "question" */
  question_stream: Array<Question>;
  /** fetch data from the table: "repetition" */
  repetition: Array<Repetition>;
  /** fetch aggregated fields from the table: "repetition" */
  repetition_aggregate: RepetitionAggregate;
  /** fetch data from the table: "repetition" using primary key columns */
  repetition_by_pk?: Maybe<Repetition>;
  /** fetch data from the table in a streaming manner: "repetition" */
  repetition_stream: Array<Repetition>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** An array relationship */
  user_jobs: Array<UserJobs>;
  /** An aggregate relationship */
  user_jobs_aggregate: UserJobsAggregate;
  /** fetch data from the table: "user_jobs" using primary key columns */
  user_jobs_by_pk?: Maybe<UserJobs>;
  /** fetch data from the table in a streaming manner: "user_jobs" */
  user_jobs_stream: Array<UserJobs>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type SubscriptionRootJobArgs = {
  distinct_on?: InputMaybe<Array<JobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobOrderBy>>;
  where?: InputMaybe<JobBoolExp>;
};


export type SubscriptionRootJobAggregateArgs = {
  distinct_on?: InputMaybe<Array<JobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobOrderBy>>;
  where?: InputMaybe<JobBoolExp>;
};


export type SubscriptionRootJobByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootJobStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobStreamCursorInput>>;
  where?: InputMaybe<JobBoolExp>;
};


export type SubscriptionRootPlacementArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


export type SubscriptionRootPlacementAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlacementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlacementOrderBy>>;
  where?: InputMaybe<PlacementBoolExp>;
};


export type SubscriptionRootPlacementByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPlacementStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlacementStreamCursorInput>>;
  where?: InputMaybe<PlacementBoolExp>;
};


export type SubscriptionRootQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type SubscriptionRootQuestionAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type SubscriptionRootQuestionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionStreamCursorInput>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type SubscriptionRootRepetitionArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


export type SubscriptionRootRepetitionAggregateArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


export type SubscriptionRootRepetitionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootRepetitionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RepetitionStreamCursorInput>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


export type SubscriptionRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


export type SubscriptionRootUserJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


export type SubscriptionRootUserJobsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserJobsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserJobsStreamCursorInput>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


export type SubscriptionRootUserStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  group?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_admin: Scalars['Boolean'];
  name: Scalars['String'];
  random_mode: Scalars['Boolean'];
  /** An array relationship */
  repetitions: Array<Repetition>;
  /** An aggregate relationship */
  repetitions_aggregate: RepetitionAggregate;
  /** An array relationship */
  user_jobs: Array<UserJobs>;
  /** An aggregate relationship */
  user_jobs_aggregate: UserJobsAggregate;
  valid_until?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "user" */
export type UserRepetitionsArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


/** columns and relationships of "user" */
export type UserRepetitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RepetitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RepetitionOrderBy>>;
  where?: InputMaybe<RepetitionBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobsOrderBy>>;
  where?: InputMaybe<UserJobsBoolExp>;
};

/** aggregated selection of "user" */
export type UserAggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type UserAggregateFields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
};


/** aggregate fields of "user" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  group?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_admin?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  random_mode?: InputMaybe<BooleanComparisonExp>;
  repetitions?: InputMaybe<RepetitionBoolExp>;
  repetitions_aggregate?: InputMaybe<RepetitionAggregateBoolExp>;
  user_jobs?: InputMaybe<UserJobsBoolExp>;
  user_jobs_aggregate?: InputMaybe<UserJobsAggregateBoolExp>;
  valid_until?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "user" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "name" */
  UserNameKey = 'user_name_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type UserInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  random_mode?: InputMaybe<Scalars['Boolean']>;
  repetitions?: InputMaybe<RepetitionArrRelInsertInput>;
  user_jobs?: InputMaybe<UserJobsArrRelInsertInput>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "user_jobs" */
export type UserJobs = {
  __typename?: 'user_jobs';
  id: Scalars['uuid'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_jobs" */
export type UserJobsAggregate = {
  __typename?: 'user_jobs_aggregate';
  aggregate?: Maybe<UserJobsAggregateFields>;
  nodes: Array<UserJobs>;
};

export type UserJobsAggregateBoolExp = {
  count?: InputMaybe<UserJobsAggregateBoolExpCount>;
};

export type UserJobsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserJobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserJobsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_jobs" */
export type UserJobsAggregateFields = {
  __typename?: 'user_jobs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserJobsMaxFields>;
  min?: Maybe<UserJobsMinFields>;
};


/** aggregate fields of "user_jobs" */
export type UserJobsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserJobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_jobs" */
export type UserJobsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserJobsMaxOrderBy>;
  min?: InputMaybe<UserJobsMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_jobs" */
export type UserJobsArrRelInsertInput = {
  data: Array<UserJobsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserJobsOnConflict>;
};

/** Boolean expression to filter rows from the table "user_jobs". All fields are combined with a logical 'AND'. */
export type UserJobsBoolExp = {
  _and?: InputMaybe<Array<UserJobsBoolExp>>;
  _not?: InputMaybe<UserJobsBoolExp>;
  _or?: InputMaybe<Array<UserJobsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  job?: InputMaybe<JobBoolExp>;
  job_id?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_jobs" */
export enum UserJobsConstraint {
  /** unique or primary key constraint on columns "id" */
  UserJobsPkey = 'user_jobs_pkey'
}

/** input type for inserting data into table "user_jobs" */
export type UserJobsInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<JobObjRelInsertInput>;
  job_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserJobsMaxFields = {
  __typename?: 'user_jobs_max_fields';
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_jobs" */
export type UserJobsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  job_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserJobsMinFields = {
  __typename?: 'user_jobs_min_fields';
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_jobs" */
export type UserJobsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  job_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_jobs" */
export type UserJobsMutationResponse = {
  __typename?: 'user_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserJobs>;
};

/** on_conflict condition type for table "user_jobs" */
export type UserJobsOnConflict = {
  constraint: UserJobsConstraint;
  update_columns?: Array<UserJobsUpdateColumn>;
  where?: InputMaybe<UserJobsBoolExp>;
};

/** Ordering options when selecting data from "user_jobs". */
export type UserJobsOrderBy = {
  id?: InputMaybe<OrderBy>;
  job?: InputMaybe<JobOrderBy>;
  job_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_jobs */
export type UserJobsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "user_jobs" */
export enum UserJobsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_jobs" */
export type UserJobsSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_jobs" */
export type UserJobsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserJobsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_jobs" */
export enum UserJobsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UserId = 'user_id'
}

export type UserJobsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserJobsSetInput>;
  where: UserJobsBoolExp;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type UserMutationResponse = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "user" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "user". */
export type UserOrderBy = {
  active?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  group?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_admin?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  random_mode?: InputMaybe<OrderBy>;
  repetitions_aggregate?: InputMaybe<RepetitionAggregateOrderBy>;
  user_jobs_aggregate?: InputMaybe<UserJobsAggregateOrderBy>;
  valid_until?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user */
export type UserPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum UserSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  RandomMode = 'random_mode',
  /** column name */
  ValidUntil = 'valid_until'
}

/** input type for updating data in table "user" */
export type UserSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  random_mode?: InputMaybe<Scalars['Boolean']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  random_mode?: InputMaybe<Scalars['Boolean']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum UserUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  RandomMode = 'random_mode',
  /** column name */
  ValidUntil = 'valid_until'
}

export type UserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type DatenmatrixQueryVariables = Exact<{ [key: string]: never; }>;


export type DatenmatrixQuery = { __typename?: 'query_root', repetition: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined, locked_at?: any | undefined, questionnare?: any | undefined, user: { __typename?: 'user', name: string, group?: string | undefined }, placements: Array<{ __typename?: 'placement', id: any, x: number, y: number, job: { __typename?: 'job', id: any, name: string, description?: string | undefined, r: number, i: number, a: number, s: number, e: number, c: number }, excluding_question?: { __typename?: 'question', id: any } | undefined }> }>, job: Array<{ __typename?: 'job', id: any, name: string }> };

export type InsertJobsMutationVariables = Exact<{
  objects: Array<JobInsertInput> | JobInsertInput;
}>;


export type InsertJobsMutation = { __typename?: 'mutation_root', insert_job?: { __typename?: 'job_mutation_response', returning: Array<{ __typename?: 'job', id: any }> } | undefined };

export type DeleteJobMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteJobMutation = { __typename?: 'mutation_root', delete_user_jobs?: { __typename?: 'user_jobs_mutation_response', returning: Array<{ __typename?: 'user_jobs', id: any }> } | undefined, delete_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined, delete_job?: { __typename?: 'job_mutation_response', returning: Array<{ __typename?: 'job', id: any }> } | undefined };

export type InsertQuestionsMutationVariables = Exact<{
  objects: Array<QuestionInsertInput> | QuestionInsertInput;
}>;


export type InsertQuestionsMutation = { __typename?: 'mutation_root', insert_question?: { __typename?: 'question_mutation_response', returning: Array<{ __typename?: 'question', id: any }> } | undefined };

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteQuestionMutation = { __typename?: 'mutation_root', update_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined, delete_question?: { __typename?: 'question_mutation_response', returning: Array<{ __typename?: 'question', id: any }> } | undefined };

export type InsertUsersMutationVariables = Exact<{
  objects: Array<UserInsertInput> | UserInsertInput;
}>;


export type InsertUsersMutation = { __typename?: 'mutation_root', insert_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', id: any, name: string }> } | undefined };

export type DeleteUsersMutationVariables = Exact<{
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteUsersMutation = { __typename?: 'mutation_root', delete_user_jobs?: { __typename?: 'user_jobs_mutation_response', returning: Array<{ __typename?: 'user_jobs', id: any }> } | undefined, delete_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined, delete_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any }> } | undefined, delete_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', id: any }> } | undefined };

export type DeleteUsersPlacementsMutationVariables = Exact<{
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteUsersPlacementsMutation = { __typename?: 'mutation_root', delete_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined };

export type ResetCurrentRepetitionMutationVariables = Exact<{
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type ResetCurrentRepetitionMutation = { __typename?: 'mutation_root', update_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined }> } | undefined };

export type SetNextRepetitionsMutationVariables = Exact<{
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type SetNextRepetitionsMutation = { __typename?: 'mutation_root', update_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any, locked_at?: any | undefined }> } | undefined };

export type DeleteUserJobsMutationVariables = Exact<{
  user_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteUserJobsMutation = { __typename?: 'mutation_root', delete_user_jobs?: { __typename?: 'user_jobs_mutation_response', returning: Array<{ __typename?: 'user_jobs', id: any }> } | undefined };

export type AuswertungQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AuswertungQuery = { __typename?: 'query_root', repetition: Array<{ __typename?: 'repetition', completed_at?: any | undefined, locked_at?: any | undefined, questionnare?: any | undefined, user: { __typename?: 'user', id: any, name: string, valid_until?: any | undefined, user_jobs: Array<{ __typename?: 'user_jobs', id: any, job_id: any }> }, placements: Array<{ __typename?: 'placement', id: any, x: number, y: number, job: { __typename?: 'job', id: any, name: string, description?: string | undefined, r: number, i: number, a: number, s: number, e: number, c: number }, excluding_question?: { __typename?: 'question', id: any } | undefined }> }> };

export type ExcludingQuestionMutationVariables = Exact<{
  id: Scalars['uuid'];
  question_id?: InputMaybe<Scalars['uuid']>;
}>;


export type ExcludingQuestionMutation = { __typename?: 'mutation_root', update_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined };

export type JobsQueryVariables = Exact<{ [key: string]: never; }>;


export type JobsQuery = { __typename?: 'query_root', job: Array<{ __typename?: 'job', active?: boolean | undefined, description?: string | undefined, id: any, name: string, r: number, i: number, a: number, s: number, e: number, c: number }> };

export type PlacementsByUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type PlacementsByUserQuery = { __typename?: 'query_root', repetition: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined, placements_aggregate: { __typename?: 'placement_aggregate', aggregate?: { __typename?: 'placement_aggregate_fields', count: number } | undefined } }> };

export type InsertPlacementsMutationVariables = Exact<{
  objects: Array<PlacementInsertInput> | PlacementInsertInput;
}>;


export type InsertPlacementsMutation = { __typename?: 'mutation_root', insert_placement?: { __typename?: 'placement_mutation_response', returning: Array<{ __typename?: 'placement', id: any }> } | undefined };

export type QuestionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type QuestionQuery = { __typename?: 'query_root', question: Array<{ __typename?: 'question', active?: boolean | undefined, id: any, text?: string | undefined, no?: string | undefined, order?: number | undefined, yes?: string | undefined, name: string }> };

export type QuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionsQuery = { __typename?: 'query_root', question: Array<{ __typename?: 'question', active?: boolean | undefined, id: any, text?: string | undefined, no?: string | undefined, order?: number | undefined, yes?: string | undefined, name: string }> };

export type RepetitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type RepetitionsQuery = { __typename?: 'query_root', repetition: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined, show_questions: boolean, questionnare?: any | undefined }> };

export type CompleteRepetitionMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type CompleteRepetitionMutation = { __typename?: 'mutation_root', update_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any }> } | undefined };

export type SetQuestionnareRepetitionMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type SetQuestionnareRepetitionMutation = { __typename?: 'mutation_root', update_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any }> } | undefined };

export type SaveQuestionnareRepetitionMutationVariables = Exact<{
  questionnare: Scalars['jsonb'];
}>;


export type SaveQuestionnareRepetitionMutation = { __typename?: 'mutation_root', update_repetition?: { __typename?: 'repetition_mutation_response', returning: Array<{ __typename?: 'repetition', id: any }> } | undefined };

export type UserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type UserQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', active: boolean, valid_until?: any | undefined, id: any, is_admin: boolean, name: string, group?: string | undefined, random_mode: boolean, created_at: any, user_jobs: Array<{ __typename?: 'user_jobs', id: any, user_id: any, job_id: any }>, repetitions: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined, placements: Array<{ __typename?: 'placement', id: any, x: number, y: number, job_id: any, job: { __typename?: 'job', active?: boolean | undefined, description?: string | undefined, id: any, name: string, r: number, i: number, a: number, s: number, e: number, c: number }, excluding_question?: { __typename?: 'question', active?: boolean | undefined, id: any, text?: string | undefined, no?: string | undefined, order?: number | undefined, yes?: string | undefined, name: string } | undefined }> }> }> };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', active: boolean, valid_until?: any | undefined, id: any, is_admin: boolean, name: string, group?: string | undefined, random_mode: boolean, created_at: any, user_jobs: Array<{ __typename?: 'user_jobs', id: any, user_id: any, job_id: any }>, repetitions: Array<{ __typename?: 'repetition', id: any, completed_at?: any | undefined }> }> };


export const DatenmatrixDocument = gql`
    query datenmatrix {
  repetition {
    id
    completed_at
    locked_at
    questionnare
    user {
      name
      group
    }
    placements(
      order_by: {dropped_at: asc}
      where: {_and: {x: {_gte: 0}, y: {_gte: 0}}}
    ) {
      id
      x
      y
      job {
        id
        name
        description
        r
        i
        a
        s
        e
        c
      }
      excluding_question {
        id
      }
    }
  }
  job {
    id
    name
  }
}
    `;

/**
 * __useDatenmatrixQuery__
 *
 * To run a query within a React component, call `useDatenmatrixQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatenmatrixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatenmatrixQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatenmatrixQuery(baseOptions?: Apollo.QueryHookOptions<DatenmatrixQuery, DatenmatrixQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatenmatrixQuery, DatenmatrixQueryVariables>(DatenmatrixDocument, options);
      }
export function useDatenmatrixLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatenmatrixQuery, DatenmatrixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatenmatrixQuery, DatenmatrixQueryVariables>(DatenmatrixDocument, options);
        }
export type DatenmatrixQueryHookResult = ReturnType<typeof useDatenmatrixQuery>;
export type DatenmatrixLazyQueryHookResult = ReturnType<typeof useDatenmatrixLazyQuery>;
export type DatenmatrixQueryResult = Apollo.QueryResult<DatenmatrixQuery, DatenmatrixQueryVariables>;
export const InsertJobsDocument = gql`
    mutation insert_jobs($objects: [job_insert_input!]!) {
  insert_job(
    on_conflict: {constraint: jobs_pkey, update_columns: [active, description, name, r, i, a, s, e, c]}
    objects: $objects
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertJobsMutationFn = Apollo.MutationFunction<InsertJobsMutation, InsertJobsMutationVariables>;

/**
 * __useInsertJobsMutation__
 *
 * To run a mutation, you first call `useInsertJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertJobsMutation, { data, loading, error }] = useInsertJobsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertJobsMutation(baseOptions?: Apollo.MutationHookOptions<InsertJobsMutation, InsertJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertJobsMutation, InsertJobsMutationVariables>(InsertJobsDocument, options);
      }
export type InsertJobsMutationHookResult = ReturnType<typeof useInsertJobsMutation>;
export type InsertJobsMutationResult = Apollo.MutationResult<InsertJobsMutation>;
export type InsertJobsMutationOptions = Apollo.BaseMutationOptions<InsertJobsMutation, InsertJobsMutationVariables>;
export const DeleteJobDocument = gql`
    mutation delete_job($id: uuid!) {
  delete_user_jobs(where: {job_id: {_eq: $id}}) {
    returning {
      id
    }
  }
  delete_placement(where: {job_id: {_eq: $id}}) {
    returning {
      id
    }
  }
  delete_job(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, options);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const InsertQuestionsDocument = gql`
    mutation insert_questions($objects: [question_insert_input!]!) {
  insert_question(
    on_conflict: {constraint: question_pkey, update_columns: [active, text, yes, no, order]}
    objects: $objects
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertQuestionsMutationFn = Apollo.MutationFunction<InsertQuestionsMutation, InsertQuestionsMutationVariables>;

/**
 * __useInsertQuestionsMutation__
 *
 * To run a mutation, you first call `useInsertQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuestionsMutation, { data, loading, error }] = useInsertQuestionsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<InsertQuestionsMutation, InsertQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertQuestionsMutation, InsertQuestionsMutationVariables>(InsertQuestionsDocument, options);
      }
export type InsertQuestionsMutationHookResult = ReturnType<typeof useInsertQuestionsMutation>;
export type InsertQuestionsMutationResult = Apollo.MutationResult<InsertQuestionsMutation>;
export type InsertQuestionsMutationOptions = Apollo.BaseMutationOptions<InsertQuestionsMutation, InsertQuestionsMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation delete_question($id: uuid!) {
  update_placement(
    where: {excluding_question_id: {_eq: $id}}
    _set: {excluding_question_id: null}
  ) {
    returning {
      id
    }
  }
  delete_question(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const InsertUsersDocument = gql`
    mutation insert_users($objects: [user_insert_input!]!) {
  insert_user(
    on_conflict: {constraint: user_pkey, update_columns: [active, valid_until, is_admin, name, group, random_mode]}
    objects: $objects
  ) {
    returning {
      id
      name
    }
  }
}
    `;
export type InsertUsersMutationFn = Apollo.MutationFunction<InsertUsersMutation, InsertUsersMutationVariables>;

/**
 * __useInsertUsersMutation__
 *
 * To run a mutation, you first call `useInsertUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUsersMutation, { data, loading, error }] = useInsertUsersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUsersMutation(baseOptions?: Apollo.MutationHookOptions<InsertUsersMutation, InsertUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUsersMutation, InsertUsersMutationVariables>(InsertUsersDocument, options);
      }
export type InsertUsersMutationHookResult = ReturnType<typeof useInsertUsersMutation>;
export type InsertUsersMutationResult = Apollo.MutationResult<InsertUsersMutation>;
export type InsertUsersMutationOptions = Apollo.BaseMutationOptions<InsertUsersMutation, InsertUsersMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation delete_users($user_ids: [uuid!]!) {
  delete_user_jobs(where: {user_id: {_in: $user_ids}}) {
    returning {
      id
    }
  }
  delete_placement(where: {repetition: {user_id: {_in: $user_ids}}}) {
    returning {
      id
    }
  }
  delete_repetition(where: {user_id: {_in: $user_ids}}) {
    returning {
      id
    }
  }
  delete_user(where: {id: {_in: $user_ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const DeleteUsersPlacementsDocument = gql`
    mutation delete_users_placements($user_ids: [uuid!]!) {
  delete_placement(
    where: {repetition: {user_id: {_in: $user_ids}, locked_at: {_is_null: true}}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUsersPlacementsMutationFn = Apollo.MutationFunction<DeleteUsersPlacementsMutation, DeleteUsersPlacementsMutationVariables>;

/**
 * __useDeleteUsersPlacementsMutation__
 *
 * To run a mutation, you first call `useDeleteUsersPlacementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersPlacementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersPlacementsMutation, { data, loading, error }] = useDeleteUsersPlacementsMutation({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useDeleteUsersPlacementsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersPlacementsMutation, DeleteUsersPlacementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersPlacementsMutation, DeleteUsersPlacementsMutationVariables>(DeleteUsersPlacementsDocument, options);
      }
export type DeleteUsersPlacementsMutationHookResult = ReturnType<typeof useDeleteUsersPlacementsMutation>;
export type DeleteUsersPlacementsMutationResult = Apollo.MutationResult<DeleteUsersPlacementsMutation>;
export type DeleteUsersPlacementsMutationOptions = Apollo.BaseMutationOptions<DeleteUsersPlacementsMutation, DeleteUsersPlacementsMutationVariables>;
export const ResetCurrentRepetitionDocument = gql`
    mutation reset_current_repetition($user_ids: [uuid!]!) {
  update_repetition(
    where: {user_id: {_in: $user_ids}, locked_at: {_is_null: true}}
    _set: {completed_at: null}
  ) {
    returning {
      id
      completed_at
    }
  }
}
    `;
export type ResetCurrentRepetitionMutationFn = Apollo.MutationFunction<ResetCurrentRepetitionMutation, ResetCurrentRepetitionMutationVariables>;

/**
 * __useResetCurrentRepetitionMutation__
 *
 * To run a mutation, you first call `useResetCurrentRepetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCurrentRepetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCurrentRepetitionMutation, { data, loading, error }] = useResetCurrentRepetitionMutation({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useResetCurrentRepetitionMutation(baseOptions?: Apollo.MutationHookOptions<ResetCurrentRepetitionMutation, ResetCurrentRepetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetCurrentRepetitionMutation, ResetCurrentRepetitionMutationVariables>(ResetCurrentRepetitionDocument, options);
      }
export type ResetCurrentRepetitionMutationHookResult = ReturnType<typeof useResetCurrentRepetitionMutation>;
export type ResetCurrentRepetitionMutationResult = Apollo.MutationResult<ResetCurrentRepetitionMutation>;
export type ResetCurrentRepetitionMutationOptions = Apollo.BaseMutationOptions<ResetCurrentRepetitionMutation, ResetCurrentRepetitionMutationVariables>;
export const SetNextRepetitionsDocument = gql`
    mutation set_next_repetitions($user_ids: [uuid!]!) {
  update_repetition(
    where: {user_id: {_in: $user_ids}, locked_at: {_is_null: true}}
    _set: {locked_at: "now()"}
  ) {
    returning {
      id
      locked_at
    }
  }
}
    `;
export type SetNextRepetitionsMutationFn = Apollo.MutationFunction<SetNextRepetitionsMutation, SetNextRepetitionsMutationVariables>;

/**
 * __useSetNextRepetitionsMutation__
 *
 * To run a mutation, you first call `useSetNextRepetitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNextRepetitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNextRepetitionsMutation, { data, loading, error }] = useSetNextRepetitionsMutation({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useSetNextRepetitionsMutation(baseOptions?: Apollo.MutationHookOptions<SetNextRepetitionsMutation, SetNextRepetitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNextRepetitionsMutation, SetNextRepetitionsMutationVariables>(SetNextRepetitionsDocument, options);
      }
export type SetNextRepetitionsMutationHookResult = ReturnType<typeof useSetNextRepetitionsMutation>;
export type SetNextRepetitionsMutationResult = Apollo.MutationResult<SetNextRepetitionsMutation>;
export type SetNextRepetitionsMutationOptions = Apollo.BaseMutationOptions<SetNextRepetitionsMutation, SetNextRepetitionsMutationVariables>;
export const DeleteUserJobsDocument = gql`
    mutation delete_user_jobs($user_ids: [uuid!]!) {
  delete_user_jobs(where: {user_id: {_in: $user_ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserJobsMutationFn = Apollo.MutationFunction<DeleteUserJobsMutation, DeleteUserJobsMutationVariables>;

/**
 * __useDeleteUserJobsMutation__
 *
 * To run a mutation, you first call `useDeleteUserJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserJobsMutation, { data, loading, error }] = useDeleteUserJobsMutation({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useDeleteUserJobsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserJobsMutation, DeleteUserJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserJobsMutation, DeleteUserJobsMutationVariables>(DeleteUserJobsDocument, options);
      }
export type DeleteUserJobsMutationHookResult = ReturnType<typeof useDeleteUserJobsMutation>;
export type DeleteUserJobsMutationResult = Apollo.MutationResult<DeleteUserJobsMutation>;
export type DeleteUserJobsMutationOptions = Apollo.BaseMutationOptions<DeleteUserJobsMutation, DeleteUserJobsMutationVariables>;
export const AuswertungDocument = gql`
    query auswertung($id: uuid!) {
  repetition(where: {id: {_eq: $id}}) {
    completed_at
    locked_at
    questionnare
    user {
      id
      name
      valid_until
      user_jobs {
        id
        job_id
      }
    }
    placements(
      order_by: {dropped_at: asc}
      where: {_and: {x: {_gte: 0}, y: {_gte: 0}}}
    ) {
      id
      x
      y
      job {
        id
        name
        description
        r
        i
        a
        s
        e
        c
      }
      excluding_question {
        id
      }
    }
  }
}
    `;

/**
 * __useAuswertungQuery__
 *
 * To run a query within a React component, call `useAuswertungQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuswertungQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuswertungQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuswertungQuery(baseOptions: Apollo.QueryHookOptions<AuswertungQuery, AuswertungQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuswertungQuery, AuswertungQueryVariables>(AuswertungDocument, options);
      }
export function useAuswertungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuswertungQuery, AuswertungQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuswertungQuery, AuswertungQueryVariables>(AuswertungDocument, options);
        }
export type AuswertungQueryHookResult = ReturnType<typeof useAuswertungQuery>;
export type AuswertungLazyQueryHookResult = ReturnType<typeof useAuswertungLazyQuery>;
export type AuswertungQueryResult = Apollo.QueryResult<AuswertungQuery, AuswertungQueryVariables>;
export const ExcludingQuestionDocument = gql`
    mutation excluding_question($id: uuid!, $question_id: uuid) {
  update_placement(
    where: {_and: {id: {_eq: $id}, repetition: {completed_at: {_is_null: true}}}}
    _set: {excluding_question_id: $question_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type ExcludingQuestionMutationFn = Apollo.MutationFunction<ExcludingQuestionMutation, ExcludingQuestionMutationVariables>;

/**
 * __useExcludingQuestionMutation__
 *
 * To run a mutation, you first call `useExcludingQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludingQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludingQuestionMutation, { data, loading, error }] = useExcludingQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useExcludingQuestionMutation(baseOptions?: Apollo.MutationHookOptions<ExcludingQuestionMutation, ExcludingQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludingQuestionMutation, ExcludingQuestionMutationVariables>(ExcludingQuestionDocument, options);
      }
export type ExcludingQuestionMutationHookResult = ReturnType<typeof useExcludingQuestionMutation>;
export type ExcludingQuestionMutationResult = Apollo.MutationResult<ExcludingQuestionMutation>;
export type ExcludingQuestionMutationOptions = Apollo.BaseMutationOptions<ExcludingQuestionMutation, ExcludingQuestionMutationVariables>;
export const JobsDocument = gql`
    query jobs {
  job {
    active
    description
    id
    name
    r
    i
    a
    s
    e
    c
  }
}
    `;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const PlacementsByUserDocument = gql`
    query placements_by_user($id: uuid) {
  repetition(order_by: {locked_at: desc}, where: {id: {_eq: $id}}) {
    id
    completed_at
    placements_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __usePlacementsByUserQuery__
 *
 * To run a query within a React component, call `usePlacementsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementsByUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlacementsByUserQuery(baseOptions?: Apollo.QueryHookOptions<PlacementsByUserQuery, PlacementsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacementsByUserQuery, PlacementsByUserQueryVariables>(PlacementsByUserDocument, options);
      }
export function usePlacementsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacementsByUserQuery, PlacementsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacementsByUserQuery, PlacementsByUserQueryVariables>(PlacementsByUserDocument, options);
        }
export type PlacementsByUserQueryHookResult = ReturnType<typeof usePlacementsByUserQuery>;
export type PlacementsByUserLazyQueryHookResult = ReturnType<typeof usePlacementsByUserLazyQuery>;
export type PlacementsByUserQueryResult = Apollo.QueryResult<PlacementsByUserQuery, PlacementsByUserQueryVariables>;
export const InsertPlacementsDocument = gql`
    mutation insert_placements($objects: [placement_insert_input!]!) {
  insert_placement(
    on_conflict: {constraint: classification_pkey, update_columns: [x, y, dropped_at]}
    objects: $objects
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertPlacementsMutationFn = Apollo.MutationFunction<InsertPlacementsMutation, InsertPlacementsMutationVariables>;

/**
 * __useInsertPlacementsMutation__
 *
 * To run a mutation, you first call `useInsertPlacementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlacementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlacementsMutation, { data, loading, error }] = useInsertPlacementsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertPlacementsMutation(baseOptions?: Apollo.MutationHookOptions<InsertPlacementsMutation, InsertPlacementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPlacementsMutation, InsertPlacementsMutationVariables>(InsertPlacementsDocument, options);
      }
export type InsertPlacementsMutationHookResult = ReturnType<typeof useInsertPlacementsMutation>;
export type InsertPlacementsMutationResult = Apollo.MutationResult<InsertPlacementsMutation>;
export type InsertPlacementsMutationOptions = Apollo.BaseMutationOptions<InsertPlacementsMutation, InsertPlacementsMutationVariables>;
export const QuestionDocument = gql`
    query question($id: uuid) {
  question(where: {id: {_eq: $id}}) {
    active
    id
    text
    no
    order
    yes
    name
  }
}
    `;

/**
 * __useQuestionQuery__
 *
 * To run a query within a React component, call `useQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionQuery(baseOptions?: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionQueryResult = Apollo.QueryResult<QuestionQuery, QuestionQueryVariables>;
export const QuestionsDocument = gql`
    query questions {
  question(order_by: [{order: asc}]) {
    active
    id
    text
    no
    order
    yes
    name
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const RepetitionsDocument = gql`
    query repetitions {
  repetition(where: {locked_at: {_is_null: true}}) {
    id
    completed_at
    show_questions
    questionnare
  }
}
    `;

/**
 * __useRepetitionsQuery__
 *
 * To run a query within a React component, call `useRepetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepetitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRepetitionsQuery(baseOptions?: Apollo.QueryHookOptions<RepetitionsQuery, RepetitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RepetitionsQuery, RepetitionsQueryVariables>(RepetitionsDocument, options);
      }
export function useRepetitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RepetitionsQuery, RepetitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RepetitionsQuery, RepetitionsQueryVariables>(RepetitionsDocument, options);
        }
export type RepetitionsQueryHookResult = ReturnType<typeof useRepetitionsQuery>;
export type RepetitionsLazyQueryHookResult = ReturnType<typeof useRepetitionsLazyQuery>;
export type RepetitionsQueryResult = Apollo.QueryResult<RepetitionsQuery, RepetitionsQueryVariables>;
export const CompleteRepetitionDocument = gql`
    mutation complete_repetition($user_id: uuid!) {
  update_repetition(
    where: {_and: {locked_at: {_is_null: true}, user_id: {_eq: $user_id}}}
    _set: {completed_at: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type CompleteRepetitionMutationFn = Apollo.MutationFunction<CompleteRepetitionMutation, CompleteRepetitionMutationVariables>;

/**
 * __useCompleteRepetitionMutation__
 *
 * To run a mutation, you first call `useCompleteRepetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRepetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRepetitionMutation, { data, loading, error }] = useCompleteRepetitionMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCompleteRepetitionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteRepetitionMutation, CompleteRepetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteRepetitionMutation, CompleteRepetitionMutationVariables>(CompleteRepetitionDocument, options);
      }
export type CompleteRepetitionMutationHookResult = ReturnType<typeof useCompleteRepetitionMutation>;
export type CompleteRepetitionMutationResult = Apollo.MutationResult<CompleteRepetitionMutation>;
export type CompleteRepetitionMutationOptions = Apollo.BaseMutationOptions<CompleteRepetitionMutation, CompleteRepetitionMutationVariables>;
export const SetQuestionnareRepetitionDocument = gql`
    mutation set_questionnare_repetition($user_id: uuid!) {
  update_repetition(
    where: {_and: {locked_at: {_is_null: true}, user_id: {_eq: $user_id}}}
    _set: {show_questions: true}
  ) {
    returning {
      id
    }
  }
}
    `;
export type SetQuestionnareRepetitionMutationFn = Apollo.MutationFunction<SetQuestionnareRepetitionMutation, SetQuestionnareRepetitionMutationVariables>;

/**
 * __useSetQuestionnareRepetitionMutation__
 *
 * To run a mutation, you first call `useSetQuestionnareRepetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionnareRepetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionnareRepetitionMutation, { data, loading, error }] = useSetQuestionnareRepetitionMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useSetQuestionnareRepetitionMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionnareRepetitionMutation, SetQuestionnareRepetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionnareRepetitionMutation, SetQuestionnareRepetitionMutationVariables>(SetQuestionnareRepetitionDocument, options);
      }
export type SetQuestionnareRepetitionMutationHookResult = ReturnType<typeof useSetQuestionnareRepetitionMutation>;
export type SetQuestionnareRepetitionMutationResult = Apollo.MutationResult<SetQuestionnareRepetitionMutation>;
export type SetQuestionnareRepetitionMutationOptions = Apollo.BaseMutationOptions<SetQuestionnareRepetitionMutation, SetQuestionnareRepetitionMutationVariables>;
export const SaveQuestionnareRepetitionDocument = gql`
    mutation save_questionnare_repetition($questionnare: jsonb!) {
  update_repetition(
    where: {locked_at: {_is_null: true}}
    _set: {questionnare: $questionnare}
  ) {
    returning {
      id
    }
  }
}
    `;
export type SaveQuestionnareRepetitionMutationFn = Apollo.MutationFunction<SaveQuestionnareRepetitionMutation, SaveQuestionnareRepetitionMutationVariables>;

/**
 * __useSaveQuestionnareRepetitionMutation__
 *
 * To run a mutation, you first call `useSaveQuestionnareRepetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQuestionnareRepetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQuestionnareRepetitionMutation, { data, loading, error }] = useSaveQuestionnareRepetitionMutation({
 *   variables: {
 *      questionnare: // value for 'questionnare'
 *   },
 * });
 */
export function useSaveQuestionnareRepetitionMutation(baseOptions?: Apollo.MutationHookOptions<SaveQuestionnareRepetitionMutation, SaveQuestionnareRepetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveQuestionnareRepetitionMutation, SaveQuestionnareRepetitionMutationVariables>(SaveQuestionnareRepetitionDocument, options);
      }
export type SaveQuestionnareRepetitionMutationHookResult = ReturnType<typeof useSaveQuestionnareRepetitionMutation>;
export type SaveQuestionnareRepetitionMutationResult = Apollo.MutationResult<SaveQuestionnareRepetitionMutation>;
export type SaveQuestionnareRepetitionMutationOptions = Apollo.BaseMutationOptions<SaveQuestionnareRepetitionMutation, SaveQuestionnareRepetitionMutationVariables>;
export const UserDocument = gql`
    query user($id: uuid) {
  user(where: {id: {_eq: $id}}) {
    active
    valid_until
    id
    is_admin
    name
    group
    random_mode
    created_at
    user_jobs {
      id
      user_id
      job_id
    }
    repetitions(order_by: {locked_at: desc}) {
      id
      completed_at
      placements(order_by: {dropped_at: asc}) {
        id
        x
        y
        job_id
        job {
          active
          description
          id
          name
          r
          i
          a
          s
          e
          c
        }
        excluding_question {
          active
          id
          text
          no
          order
          yes
          name
        }
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users {
  user(order_by: {created_at: desc}) {
    active
    valid_until
    id
    is_admin
    name
    group
    random_mode
    created_at
    user_jobs {
      id
      user_id
      job_id
    }
    repetitions(order_by: {locked_at: desc}) {
      id
      completed_at
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;