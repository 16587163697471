import { Spin } from 'antd';
import { Title } from './title';
import { useFela } from '../theme';
import React from 'react';

interface IInfo {
  children?: React.ReactNode | Array<React.ReactNode>;
  className?: string;
  loading?: boolean;
  active?: boolean;
  title?: string | null;
  shrink?: boolean;
  onClose?: () => void;
}

export function Info({
  children,
  className,
  loading,
  active,
  title,
  shrink,
  onClose,
}: IInfo) {
  const { css, theme } = useFela();

  return (
    <>
      {!!title && <Title onClose={onClose}>{title}</Title>}
      <div
        className={`${css({
          position: 'relative',
          overflow: 'auto',
          hyphens: 'auto',
          backgroundColor:
            loading || active
              ? theme.palette.primary[1]
              : theme.palette.grey[1],
          borderRadius: theme.border.base.borderRadius,
          borderTop: title ? `2px solid ${theme.palette.primary.base}` : 'none',
          transition: 'background-color 0.2s',
          boxShadow: theme.boxShadow[1].toString,
          padding: theme.spacing.sm,
          marginY: theme.spacing.xs,
          flexGrow: 1,
          flexShrink: shrink ? 0 : 1,
          '> p:last-child': {
            marginBottom: 0,
          },
        })} ${className || ''}`}
      >
        {loading ? (
          <div className={css({ center: 'true' })}>
            <Spin />
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
}
