import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { PropsWithChildren, Suspense, lazy, useEffect } from 'react';
import { useRepetition, useSession } from '../graphql';
import NProgress from 'nprogress';

const Home = lazy(() => import('../pages/home'));
const Antworten = lazy(() => import('../pages/antworten'));
const Platzieren = lazy(() => import('../pages/platzieren'));
const Eingrenzen = lazy(() => import('../pages/eingrenzen'));
const Auswerten = lazy(() => import('../pages/auswerten'));
const Screenshot = lazy(() => import('../pages/screenshot'));
const Download = lazy(() => import('../pages/download'));
const Impressum = lazy(() => import('../pages/impressum'));
const AdminDatenmatrix = lazy(() => import('../pages/admin/datenmatrix'));
const AdminJobs = lazy(() => import('../pages/admin/jobs'));
const AdminQuestions = lazy(() => import('../pages/admin/questions'));
const AdminUsers = lazy(() => import('../pages/admin/users'));

const LazyLoad = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return null;
};

function AdminPage({ children }: PropsWithChildren<unknown>) {
  const { session } = useSession();

  return session.id && session.isAdmin ? <>{children}</> : <Navigate to="/" />;
}

function ModeratorPage({ children }: PropsWithChildren<unknown>) {
  const { session } = useSession();

  return session.id && session.isModerator ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
}

function PrivatePage({
  completed,
  children,
}: PropsWithChildren<{ completed?: boolean }>) {
  const { session } = useSession();
  const [repetition, { loading }] = useRepetition();
  const push = useNavigate();

  useEffect(() => {
    if (repetition)
      if (
        (completed && repetition?.completed_at === null) ||
        (!completed && repetition?.completed_at !== null)
      )
        push('/');
  }, [repetition]);

  return loading ? (
    <LazyLoad />
  ) : session.id ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
}

export function Router() {
  return (
    <Suspense fallback={<LazyLoad />}>
      <Routes>
        <Route path="/">
          <Route path="admin">
            <Route
              path="datenmatrix"
              element={
                <AdminPage>
                  <AdminDatenmatrix />
                </AdminPage>
              }
            />
            <Route
              path="jobs"
              element={
                <AdminPage>
                  <AdminJobs />
                </AdminPage>
              }
            />
            <Route
              path="questions"
              element={
                <AdminPage>
                  <AdminQuestions />
                </AdminPage>
              }
            />
            <Route
              path="users"
              element={
                <ModeratorPage>
                  <AdminUsers />
                </ModeratorPage>
              }
            />
            <Route
              path="screenshot"
              element={
                <AdminPage>
                  <Screenshot />
                </AdminPage>
              }
            />
            <Route
              index
              element={
                <ModeratorPage>
                  <AdminUsers />
                </ModeratorPage>
              }
            />
          </Route>

          <Route
            path="antworten"
            element={
              <PrivatePage>
                <Antworten />
              </PrivatePage>
            }
          />
          <Route
            path="platzieren"
            element={
              <PrivatePage>
                <Platzieren />
              </PrivatePage>
            }
          />
          <Route
            path="eingrenzen"
            element={
              <PrivatePage>
                <Eingrenzen />
              </PrivatePage>
            }
          />
          <Route
            path="auswerten"
            element={
              <PrivatePage completed>
                <Auswerten />
              </PrivatePage>
            }
          />
        </Route>

        <Route path="/download" element={<Download />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route index element={<Home />} />
      </Routes>
    </Suspense>
  );
}
