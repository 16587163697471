import { DragSourceMonitor, useDrag } from 'react-dnd-cjs';
import { ReactComponent as Times } from '../icons/times.svg';
import { Tooltip } from 'antd';
import { getEmptyImage } from 'react-dnd-html5-backend-cjs';
import { useFela } from '../theme';
import React from 'react';

const normal = [
  [
    '#e8eaf6',
    '#e3f2fd',
    '#e0f7fa',
    '#e8f5e9',
    '#f9fbe7',
    '#fffde7',
    '#fff3e0',
    '#ffebee',
    '#fce4ec',
    '#fff0f3',
  ],
  [
    '#c5cae9',
    '#bbdefb',
    '#b2ebf2',
    '#c8e6c9',
    '#f0f4c3',
    '#fff9c4',
    '#ffe0b2',
    '#ffcdd2',
    '#f8bbd0',
    '#f2c2ce',
  ],
  [
    '#9fa8da',
    '#90caf9',
    '#80deea',
    '#a5d6a7',
    '#e6ee9c',
    '#fff59d',
    '#ffcc80',
    '#ef9a9a',
    '#f48fb1',
    '#e693aa',
  ],
  [
    '#7986cb',
    '#64b5f6',
    '#4dd0e1',
    '#81c784',
    '#dce775',
    '#fff176',
    '#ffb74d',
    '#e57373',
    '#f06292',
    '#d9688c',
  ],
  [
    '#5c6bc0',
    '#42a5f5',
    '#26c6da',
    '#66bb6a',
    '#d4e157',
    '#ffee58',
    '#ffa726',
    '#ef5350',
    '#ec407a',
    '#cc4172',
  ],
  [
    '#3f51b5',
    '#2196f3',
    '#00bcd4',
    '#4caf50',
    '#cddc39',
    '#ffeb3b',
    '#ff9800',
    '#f44336',
    '#e91e63',
    '#bf1f5c',
  ],
  [
    '#3949ab',
    '#1e88e5',
    '#00acc1',
    '#43a047',
    '#c0ca33',
    '#fdd835',
    '#fb8c00',
    '#e53935',
    '#d81b60',
    '#99114a',
  ],
  [
    '#303f9f',
    '#1976d2',
    '#0097a7',
    '#388e3c',
    '#afb42b',
    '#fbc02d',
    '#f57c00',
    '#d32f2f',
    '#c2185b',
    '#730737',
  ],
  [
    '#283593',
    '#1565c0',
    '#00838f',
    '#2e7d32',
    '#9e9d24',
    '#f9a825',
    '#ef6c00',
    '#c62828',
    '#ad1457',
    '#4d0125',
  ],
  [
    '#1a237e',
    '#0d47a1',
    '#006064',
    '#1b5e20',
    '#827717',
    '#f57f17',
    '#e65100',
    '#b71c1c',
    '#880e4f',
    '#260014',
  ],
];
const text = [
  [
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
  ],
  [
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
  ],
  [
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
  ],
  [
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
  ],
  [
    '#FFFFFF',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
  ],
  [
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
];

export function DragJob({
  placement_id,
  job_id,
  ...rest
}: IJob & { placement_id?: string; job_id?: string }) {
  const [{ canDrag, isDragging }, drag, preview] = useDrag({
    item: { placement_id, job_id, type: 'job' },
    collect: (monitor: DragSourceMonitor) => ({
      canDrag: monitor.canDrag(),
      isDragging: monitor.isDragging(),
    }),
  });

  React.useEffect(() => {
    preview(getEmptyImage());
  }, []);

  return <Job ref={drag} canDrag={canDrag} isDragging={isDragging} {...rest} />;
}

export interface IJob {
  children?: React.ReactNode | Array<React.ReactNode>;
  className?: string;

  hue?: number;
  saturation?: number;
  opacity?: number;

  canDrag?: boolean;
  isDragging?: boolean;

  state?: 'active' | 'disabled' | 'deleted';
  showContent?: boolean;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function _Job(
  {
    children,
    className,

    hue = 9,
    saturation = 5,
    opacity = 1,

    canDrag,
    isDragging,

    state,
    showContent = true,

    onClick,
    onMouseEnter,
    onMouseLeave,
  }: IJob,
  ref: React.Ref<HTMLDivElement>,
) {
  const { css, theme } = useFela();

  const color =
    state === 'deleted' || state === 'disabled'
      ? '#FFFFFF'
      : state === 'active'
      ? '#000000'
      : text[saturation][hue];

  const background =
    state === 'deleted' || state === 'disabled'
      ? theme.palette.grey[7]
      : state === 'active'
      ? theme.palette.secondary.base
      : normal[saturation][hue];

  const content = (
    <div
      ref={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${css({
        userSelect: 'none',
        display: isDragging ? 'none' : 'block',
        cursor: canDrag ? 'move' : onClick ? 'pointer' : 'initial',
        position: 'relative',
        width: '100%',
        maxWidth: 120,
        maxHeight: 120,
        boxShadow: theme.boxShadow[1].toString,
        color,
        fontSize: theme.font.sm.fontSize,
        fontWeight: 'bold',
        overflow: 'hidden',
      })} ${className || ''}`}
    >
      <div className={css({ paddingTop: '100%', opacity, background })} />

      {state === 'disabled' && (
        <Times
          className={css({
            center: 'true',
            size: showContent ? 64 : 24,
            opacity: 0.2,
          })}
        />
      )}

      {!!children && !!showContent && (
        <div
          className={css({
            center: 'true',
            width: '100%',
            maxHeight: '100%',
            lineHeight: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            hyphens: 'auto',
            padding: theme.spacing.xxs,
            textAlign: 'center',
          })}
        >
          {children}
        </div>
      )}
    </div>
  );

  return showContent ? content : <Tooltip title={children}>{content}</Tooltip>;
}
export const Job = React.forwardRef(_Job);
