import { useFela } from '../theme';

interface IHint {
  children: React.ReactNode | Array<React.ReactNode>;
  className?: string;
}

export function Hint({ children, className }: IHint) {
  const { css, theme } = useFela();

  return (
    <div
      className={`${css({
        backgroundColor: theme.palette.primary.base,
        color: theme.white,
        padding: theme.spacing.sm,
        textAlign: 'center',
        fontSize: theme.heading[4].fontSize,
      })} ${className || ''}`}
    >
      {children}
    </div>
  );
}
