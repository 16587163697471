import { CloudDownloadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useCallback, useState } from 'react';
import Search from 'antd/lib/input/Search';
import moment from 'moment';

export function Email({
  id,
  code,
  valid_until,
}: {
  id?: string | null;
  code?: string | null;
  valid_until?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const getDownloadLink = useCallback(
    async (email: string) => {
      setLoading(true);
      setDisabled(true);

      try {
        const date = moment(valid_until);
        const response = await fetch(
          `${process.env.REACT_APP_HOST}/${process.env.REACT_APP_LAMBDA_PATH}/${process.env.REACT_APP_LAMBDA_POSTMARK_ENDPOINT}`,
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
              id,
              email,
              code,
              valid_until: valid_until
                ? `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`
                : '-',
            }),
          },
        );
        const { errors, ...res } = await response.json();

        if (errors)
          throw Error(
            Array.isArray(errors)
              ? errors.map(({ msg }: { msg: string }) => msg).join(', ')
              : errors,
          );

        message.success('Ergebnisse wurden an E-Mail geschickt!');

        return res;
      } catch (error) {
        console.error(
          'You have an error in your code or there are network issues.',
          error,
        );
        setDisabled(false);

        message.success('Fehler bei E-Mail-Versand!');

        return {};
      } finally {
        setLoading(false);
      }
    },
    [id, code, valid_until],
  );

  const onCopyPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (process.env.NODE_ENV === 'development') return e;

      e.preventDefault();
      return false;
    },
    [],
  );

  return (
    <Search
      disabled={disabled}
      placeholder="E-Mail"
      enterButton={<CloudDownloadOutlined />}
      size="large"
      loading={loading}
      autoComplete="off"
      onSearch={(email) => getDownloadLink(email)}
      onPaste={onCopyPaste}
      onCopy={onCopyPaste}
    />
  );
}
