import {
  PlacementsByUserQuery,
  PlacementsByUserQueryVariables,
  usePlacementsByUserQuery,
} from '../autogenerated';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import gql from 'graphql-tag';

gql`
  query placements_by_user($id: uuid) {
    repetition(order_by: { locked_at: desc }, where: { id: { _eq: $id } }) {
      id
      completed_at
      placements_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export type PlacementsByUserType = Array<{
  id?: string;
  completed_at?: null | string;
  placements_aggregate?: {
    aggregate?: {
      count?: number;
    };
  };
}>;

export function usePlacementsByUser(
  props?: QueryHookOptions<
    PlacementsByUserQuery,
    PlacementsByUserQueryVariables
  >,
): [
  PlacementsByUserType,
  QueryResult<PlacementsByUserQuery, PlacementsByUserQueryVariables>,
] {
  const result = usePlacementsByUserQuery(props);

  return [result.data?.repetition || [], result];
}
