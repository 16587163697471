import { getSignature } from './get-signature';

export const deleteImage = async (public_id: string) => {
  const { signature, timestamp } = await getSignature({ public_id });

  const body = new FormData();
  body.append('public_id', public_id);
  body.append('signature', signature);
  body.append('timestamp', timestamp);
  body.append('api_key', process.env.REACT_APP_CLOUDINARY_API || '');

  fetch(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD}/image/destroy`,
    { method: 'POST', body },
  ).catch(console.error);
};
