import { useFela } from '../theme';
import React from 'react';

interface IProgressBar {
  children: React.ReactNode | Array<React.ReactNode>;
  progress: number;
  active?: boolean;
  onClick?: () => void;
}

export function ProgressBar({
  children,
  progress,
  active,
  onClick,
}: IProgressBar) {
  const { css, theme } = useFela();
  const size = 48;
  const color = active ? 'secondary' : 'primary';

  return (
    <div
      onClick={onClick}
      className={css({
        marginY: theme.spacing.xxs,
        borderRadius: theme.border.base.borderRadius,
        // boxShadow: active
        //   ? theme.boxShadow[3].toString
        //   : theme.boxShadow[1].toString,
        cursor: onClick ? 'pointer' : 'initial',
      })}
    >
      <div
        className={css({
          position: 'relative',
          borderRadius: theme.border.base.borderRadius,
          overflow: 'hidden',
        })}
      >
        <h6
          className={css({
            centerY: 'true',
            left: 0,
            right: size,
            hyphens: 'auto',
            paddingX: theme.spacing.sm,
            color: theme.white,
            textShadow: theme.textShadow.base.toString,
            lineHeight: 1,
          })}
        >
          {children}
        </h6>

        <h5
          className={css({
            centerY: 'true',
            right: 0,
            paddingX: theme.spacing.sm,
            color: theme.white,
            textDecoration: 'underline',
            textDecorationColor: theme.palette[color].base,
            textShadow: theme.textShadow.base.toString,
          })}
        >
          {Math.floor(progress || 0)}%
        </h5>

        <div className={css({ display: 'flex', height: size })}>
          <div
            className={css({
              backgroundColor: theme.palette[color].base,
              height: '100%',
              flexWidth: `${progress || 1}%`,
            })}
          />
          <div
            className={css({
              backgroundColor: theme.palette.grey[7],
              height: '100%',
              flexGrow: 1,
            })}
          />
        </div>
      </div>
    </div>
  );
}
