import {
  RendererProvider,
  ThemeProvider,
  useFela as useFelaBase,
} from 'react-fela';
import { createRenderer } from 'fela';
import { parseInt } from './utils';
import React from 'react';
import customProperty from 'fela-plugin-custom-property';
import embedded from 'fela-plugin-embedded';
import fallbackValue from 'fela-plugin-fallback-value';
import friendlyPseudoClass from 'fela-plugin-friendly-pseudo-class';
import namedKeys from 'fela-plugin-named-keys';
import prefixer from 'fela-plugin-prefixer';
import theme from './theme.json';
import typescript from 'fela-plugin-typescript';
import unit from 'fela-plugin-unit';
import validator from 'fela-plugin-validator';
import webPreset from 'fela-preset-web';

const sizes = {
  extraLarge: parseInt(theme.screen.xxl),
  huge: parseInt(theme.screen.xl),
  large: parseInt(theme.screen.lg),
  medium: parseInt(theme.screen.md),
  small: parseInt(theme.screen.sm),
  mini: parseInt(theme.screen.xs),
  tiny: parseInt(theme.screen.xxs),
};
const customProperties = {
  size: (size: number | string) => ({
    width: size,
    height: size,
  }),
  paddingX: (padding: number | string) => ({
    paddingLeft: padding,
    paddingRight: padding,
  }),
  paddingY: (padding: number | string) => ({
    paddingTop: padding,
    paddingBottom: padding,
  }),
  marginX: (margin: number | string) => ({
    marginLeft: margin,
    marginRight: margin,
  }),
  marginY: (margin: number | string) => ({
    marginTop: margin,
    marginBottom: margin,
  }),
  borderX: (border: string) => ({
    borderLeft: border,
    borderRight: border,
  }),
  borderY: (border: string) => ({
    borderTop: border,
    borderBottom: border,
  }),
  ellipsis: (ellipsis: string | number) =>
    ellipsis
      ? {
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: ellipsis === 'true' ? '100%' : ellipsis,
        }
      : {},
  clearfix: (clearfix: string) =>
    clearfix === 'true'
      ? {
          ':after': {
            content: '""',
            clear: 'both',
            display: 'block',
            visibility: 'hidden',
            height: 0,
          },
        }
      : {},
  center: (center: string) =>
    center === 'true'
      ? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }
      : {},
  centerX: (center: string) =>
    center === 'true'
      ? {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }
      : {},
  centerY: (center: string) =>
    center === 'true'
      ? {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        }
      : {},
  flexWidth: (width: number | string) => ({
    maxWidth: width,
    minWidth: width,
    width,
  }),
};

export const defaultRenderer = createRenderer({
  plugins: [
    typescript(),
    embedded(),
    prefixer(),
    fallbackValue(),
    unit(),
    namedKeys({
      // Up (z.B. hugeUp: x >= huge)
      ifHugeUp: `@media only screen and (min-width: ${sizes.extraLarge}px)`,
      ifExtraLargeUp: `@media only screen and (min-width: ${sizes.huge}px)`,
      ifLargeUp: `@media only screen and (min-width: ${sizes.large}px)`,
      ifMediumUp: `@media only screen and (min-width: ${sizes.medium}px)`,
      ifSmallUp: `@media only screen and (min-width: ${sizes.small}px)`,
      ifExtraSmallUp: `@media only screen and (min-width: ${sizes.mini}px)`,
      ifMiniUp: `@media only screen and (min-width: ${sizes.tiny}px)`,
      // Down (z.B. hugeDown: x <= huge)
      ifExtraLargeDown: `@media only screen and (max-width: ${
        sizes.extraLarge - 1
      }px)`,
      ifLargeDown: `@media only screen and (max-width: ${sizes.huge - 1}px)`,
      ifMediumDown: `@media only screen and (max-width: ${sizes.large - 1}px)`,
      ifSmallDown: `@media only screen and (max-width: ${sizes.medium - 1}px)`,
      ifExtraSmallDown: `@media only screen and (max-width: ${
        sizes.small - 1
      }px)`,
      ifMiniDown: `@media only screen and (max-width: ${sizes.mini - 1}px)`,
      ifTinyDown: `@media only screen and (max-width: ${sizes.tiny - 1}px)`,
      // Exact
      ifHuge: `@media only screen and (min-width: ${sizes.extraLarge}px)`,
      ifExtraLarge: `@media only screen and (max-width: ${
        sizes.extraLarge - 1
      }px) and (min-width: ${sizes.huge}px)`,
      ifLarge: `@media only screen and (max-width: ${
        sizes.huge - 1
      }px) and (min-width: ${sizes.large}px)`,
      ifMedium: `@media only screen and (max-width: ${
        sizes.large - 1
      }px) and (min-width: ${sizes.medium}px)`,
      ifSmall: `@media only screen and (max-width: ${
        sizes.medium - 1
      }px) and (min-width: ${sizes.small}px)`,
      ifExtraSmall: `@media only screen and (max-width: ${
        sizes.small - 1
      }px) and (min-width: ${sizes.mini}px)`,
      ifMini: `@media only screen and (max-width: ${
        sizes.mini - 1
      }px) and (min-width: ${sizes.tiny}px)`,
      ifTiny: `@media only screen and (max-width: ${sizes.tiny - 1}px)`,
      // Screen-orientation
      ifLandscape: '@media only screen and (orientation:landscape)',
      ifPortrait: '@media only screen and (orientation:portrait)',
    }),
    customProperty(customProperties),
    friendlyPseudoClass(),
    validator(),
    ...webPreset,
  ],
});

export const useFela = () =>
  useFelaBase<typeof theme, typeof customProperties>();

export function FelaProvider({ children }: React.PropsWithChildren<unknown>) {
  return (
    <RendererProvider renderer={defaultRenderer}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </RendererProvider>
  );
}
