export const getSignature = async (fields: unknown) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/${process.env.REACT_APP_LAMBDA_PATH}/${process.env.REACT_APP_LAMBDA_CLOUDINARY_ENDPOINT}`,
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(fields),
      },
    );
    const { errors, ...res } = await response.json();

    if (errors)
      throw Error(
        Array.isArray(errors)
          ? errors.map(({ msg }: { msg: string }) => msg).join(', ')
          : errors,
      );
    if (!res.timestamp) throw Error('Unvalid Timestamp!');

    return res;
  } catch (error) {
    console.error(
      'You have an error in your code or there are network issues.',
      error,
    );

    return {};
  }
};
