import { Button } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useFela } from '../theme';

export default function Player({ url }: { url?: string }) {
  const { css, theme } = useFela();
  const [audio] = useState(new Audio(`/audio${url}`));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));

    return () => {
      audio.pause();
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return (
    <Button
      size="large"
      icon={!playing ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
      onClick={toggle}
      disabled={!url}
      className={css({
        boxShadow: theme.boxShadow[3].toString,
        border: 'none',
      })}
    >
      Erklärung {!playing ? 'abspielen' : 'pausieren'}
    </Button>
  );
}
